/* ******************************************************* //
// NO EDITAR ESTE ARCHIVO                                  //
// ******************************************************* */
/* ******************************************************* //

DESIGN & DEVELOPMENT BY ESTUDIO PILETA
www.pileta.tv

// ******************************************************* */
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/proximanova-light-webfont-webfont.eot');
  src: url('fonts/proximanova-light-webfont-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-light-webfont-webfont.woff') format('woff'), url('fonts/proximanova-light-webfont-webfont.ttf') format('truetype'), url('fonts/proximanova-light-webfont-webfont.svg#ProximaNovaLight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/proximanova-light-webfont-webfont.svg') format('svg');
    font-weight: 300;
    font-style: normal;
  }
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/proximanova-regular-webfont-webfont.eot');
  src: url('fonts/proximanova-regular-webfont-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-regular-webfont-webfont.woff') format('woff'), url('fonts/proximanova-regular-webfont-webfont.ttf') format('truetype'), url('fonts/proximanova-regular-webfont-webfont.svg#ProximaNovaRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/proximanova-regular-webfont-webfont.svg') format('svg');
    font-weight: 400;
    font-style: normal;
  }
}
@font-face {
  font-family: 'ProximaNovaSemibold';
  src: url('fonts/proximanova-semibold-webfont-webfont.eot');
  src: url('fonts/proximanova-semibold-webfont-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-semibold-webfont-webfont.woff') format('woff'), url('fonts/proximanova-semibold-webfont-webfont.ttf') format('truetype'), url('fonts/proximanova-semibold-webfont-webfont.svg#ProximaNovaSemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/proximanova-semibold-webfont-webfont.svg') format('svg');
    font-weight: 600;
    font-style: normal;
  }
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('fonts/proximanova-bold-webfont-webfont.eot');
  src: url('fonts/proximanova-bold-webfont-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-bold-webfont-webfont.woff') format('woff'), url('fonts/proximanova-bold-webfont-webfont.ttf') format('truetype'), url('fonts/proximanova-bold-webfont-webfont.svg#ProximaNovaBold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/proximanova-bold-webfont-webfont.svg') format('svg');
    font-weight: 700;
    font-style: normal;
  }
}
/*
@font-face {
    font-family: 'ProximaNovaBlack';
    src: url('fonts/proximanova-black-webfont-webfont.eot');
    src: url('fonts/proximanova-black-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/proximanova-black-webfont-webfont.woff') format('woff'),
         url('fonts/proximanova-black-webfont-webfont.ttf') format('truetype'),
         url('fonts/proximanova-black-webfont-webfont.svg#ProximaNovaBlack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNovaExtrabold';
    src: url('fonts/proximanova-extrabold-webfont-webfont.eot');
    src: url('fonts/proximanova-extrabold-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/proximanova-extrabold-webfont-webfont.woff') format('woff'),
         url('fonts/proximanova-extrabold-webfont-webfont.ttf') format('truetype'),
         url('fonts/proximanova-extrabold-webfont-webfont.svg#ProximaNovaExtrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
*/
@font-face {
  font-family: 'ecotaxi';
  src: url('fonts/ecotaxi.eot');
  src: url('fonts/ecotaxi.eot?#iefix') format('embedded-opentype'), url('fonts/ecotaxi.woff') format('woff'), url('fonts/ecotaxi.ttf') format('truetype'), url('fonts/ecotaxi.svg#ecotaxi') format('svg');
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'ecotaxi';
    src: url('fonts/ecotaxi.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
/* Use the following CSS code if you want to use data attributes for inserting your icons */
/*
[data-icon]:before {
	font-family: 'ecotaxi';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}


/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="eco-icon-"] {
*/
.eco-icon-taxi,
.eco-icon-android,
.eco-icon-facebook,
.eco-icon-twitter,
.eco-icon-google-plus,
.eco-icon-check-alt,
.eco-icon-x-altx-alt,
.eco-icon-sort,
.eco-icon-chevron-down,
.eco-icon-chevron-up,
.eco-icon-chevron-right,
.eco-icon-chevron-left,
.eco-icon-download,
.eco-icon-download-alt,
.eco-icon-user,
.eco-icon-apple,
.eco-icon-arrow-down,
.eco-icon-checkmark,
.eco-icon-steering-wheel,
.eco-icon-help,
.eco-icon-twitter-2,
.eco-icon-chofer,
.eco-icon-caret-down,
.eco-icon-caret-up,
.eco-icon-caret-left,
.eco-icon-caret-right,
.eco-icon-office,
.eco-icon-user-2,
.eco-icon-briefcase,
.eco-icon-warning {
  font-family: 'ecotaxi';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.eco-icon-taxi:before {
  content: "\74";
}
.eco-icon-android:before {
  content: "\21";
}
.eco-icon-facebook:before {
  content: "\23";
}
.eco-icon-twitter:before {
  content: "\24";
}
.eco-icon-google-plus:before {
  content: "\25";
}
.eco-icon-check-alt:before {
  content: "\26";
}
.eco-icon-x-altx-alt:before {
  content: "\27";
}
.eco-icon-sort:before {
  content: "\f0dc";
}
.eco-icon-chevron-down:before {
  content: "\f078";
}
.eco-icon-chevron-up:before {
  content: "\f077";
}
.eco-icon-chevron-right:before {
  content: "\f054";
}
.eco-icon-chevron-left:before {
  content: "\f053";
}
.eco-icon-download:before {
  content: "\f01a";
}
.eco-icon-download-alt:before {
  content: "\f019";
}
.eco-icon-user:before {
  content: "\e000";
}
.eco-icon-apple:before {
  content: "\22";
}
.eco-icon-arrow-down:before {
  content: "\29";
}
.eco-icon-checkmark:before {
  content: "\2a";
}
.eco-icon-steering-wheel:before {
  content: "\2b";
}
.eco-icon-help:before {
  content: "\28";
}
.eco-icon-twitter-2:before {
  content: "\2c";
}
.eco-icon-chofer:before {
  content: "\2d";
}
.eco-icon-caret-down:before {
  content: "\f0d7";
}
.eco-icon-caret-up:before {
  content: "\f0d8";
}
.eco-icon-caret-left:before {
  content: "\f0d9";
}
.eco-icon-caret-right:before {
  content: "\f0da";
}
.eco-icon-office:before {
  content: "\e002";
}
.eco-icon-user-2:before {
  content: "\e004";
}
.eco-icon-briefcase:before {
  content: "\f0b1";
}
.eco-icon-warning:before {
  content: "\e001";
}
/*!
 * Bootstrap v2.3.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover,
a:active {
  outline: 0;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.google-maps img {
  max-width: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
body {
  margin: 0;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  background-color: #ffffff;
}
a {
  color: #0088cc;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #004466;
  text-decoration: underline;
}
.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}
.row {
  margin-left: -20px;
  *zoom: 1;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}
.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.span12 {
  width: 940px;
}
.span11 {
  width: 860px;
}
.span10 {
  width: 780px;
}
.span9 {
  width: 700px;
}
.span8 {
  width: 620px;
}
.span7 {
  width: 540px;
}
.span6 {
  width: 460px;
}
.span5 {
  width: 380px;
}
.span4 {
  width: 300px;
}
.span3 {
  width: 220px;
}
.span2 {
  width: 140px;
}
.span1 {
  width: 60px;
}
.offset12 {
  margin-left: 980px;
}
.offset11 {
  margin-left: 900px;
}
.offset10 {
  margin-left: 820px;
}
.offset9 {
  margin-left: 740px;
}
.offset8 {
  margin-left: 660px;
}
.offset7 {
  margin-left: 580px;
}
.offset6 {
  margin-left: 500px;
}
.offset5 {
  margin-left: 420px;
}
.offset4 {
  margin-left: 340px;
}
.offset3 {
  margin-left: 260px;
}
.offset2 {
  margin-left: 180px;
}
.offset1 {
  margin-left: 100px;
}
.row-fluid {
  width: 100%;
  *zoom: 1;
}
.row-fluid:before,
.row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.row-fluid:after {
  clear: both;
}
.row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
}
.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.row-fluid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.12765957%;
}
.row-fluid .span12 {
  width: 100%;
  *width: 99.94680851%;
}
.row-fluid .span11 {
  width: 91.4893617%;
  *width: 91.43617021%;
}
.row-fluid .span10 {
  width: 82.9787234%;
  *width: 82.92553191%;
}
.row-fluid .span9 {
  width: 74.46808511%;
  *width: 74.41489362%;
}
.row-fluid .span8 {
  width: 65.95744681%;
  *width: 65.90425532%;
}
.row-fluid .span7 {
  width: 57.44680851%;
  *width: 57.39361702%;
}
.row-fluid .span6 {
  width: 48.93617021%;
  *width: 48.88297872%;
}
.row-fluid .span5 {
  width: 40.42553191%;
  *width: 40.37234043%;
}
.row-fluid .span4 {
  width: 31.91489362%;
  *width: 31.86170213%;
}
.row-fluid .span3 {
  width: 23.40425532%;
  *width: 23.35106383%;
}
.row-fluid .span2 {
  width: 14.89361702%;
  *width: 14.84042553%;
}
.row-fluid .span1 {
  width: 6.38297872%;
  *width: 6.32978723%;
}
.row-fluid .offset12 {
  margin-left: 104.25531915%;
  *margin-left: 104.14893617%;
}
.row-fluid .offset12:first-child {
  margin-left: 102.12765957%;
  *margin-left: 102.0212766%;
}
.row-fluid .offset11 {
  margin-left: 95.74468085%;
  *margin-left: 95.63829787%;
}
.row-fluid .offset11:first-child {
  margin-left: 93.61702128%;
  *margin-left: 93.5106383%;
}
.row-fluid .offset10 {
  margin-left: 87.23404255%;
  *margin-left: 87.12765957%;
}
.row-fluid .offset10:first-child {
  margin-left: 85.10638298%;
  *margin-left: 85%;
}
.row-fluid .offset9 {
  margin-left: 78.72340426%;
  *margin-left: 78.61702128%;
}
.row-fluid .offset9:first-child {
  margin-left: 76.59574468%;
  *margin-left: 76.4893617%;
}
.row-fluid .offset8 {
  margin-left: 70.21276596%;
  *margin-left: 70.10638298%;
}
.row-fluid .offset8:first-child {
  margin-left: 68.08510638%;
  *margin-left: 67.9787234%;
}
.row-fluid .offset7 {
  margin-left: 61.70212766%;
  *margin-left: 61.59574468%;
}
.row-fluid .offset7:first-child {
  margin-left: 59.57446809%;
  *margin-left: 59.46808511%;
}
.row-fluid .offset6 {
  margin-left: 53.19148936%;
  *margin-left: 53.08510638%;
}
.row-fluid .offset6:first-child {
  margin-left: 51.06382979%;
  *margin-left: 50.95744681%;
}
.row-fluid .offset5 {
  margin-left: 44.68085106%;
  *margin-left: 44.57446809%;
}
.row-fluid .offset5:first-child {
  margin-left: 42.55319149%;
  *margin-left: 42.44680851%;
}
.row-fluid .offset4 {
  margin-left: 36.17021277%;
  *margin-left: 36.06382979%;
}
.row-fluid .offset4:first-child {
  margin-left: 34.04255319%;
  *margin-left: 33.93617021%;
}
.row-fluid .offset3 {
  margin-left: 27.65957447%;
  *margin-left: 27.55319149%;
}
.row-fluid .offset3:first-child {
  margin-left: 25.53191489%;
  *margin-left: 25.42553191%;
}
.row-fluid .offset2 {
  margin-left: 19.14893617%;
  *margin-left: 19.04255319%;
}
.row-fluid .offset2:first-child {
  margin-left: 17.0212766%;
  *margin-left: 16.91489362%;
}
.row-fluid .offset1 {
  margin-left: 10.63829787%;
  *margin-left: 10.53191489%;
}
.row-fluid .offset1:first-child {
  margin-left: 8.5106383%;
  *margin-left: 8.40425532%;
}
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}
[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}
.container {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  *zoom: 1;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.container-fluid:after {
  clear: both;
}
p {
  margin: 0 0 11px;
}
.lead {
  margin-bottom: 22px;
  font-size: 24px;
  font-weight: 200;
  line-height: 33px;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}
.muted {
  color: #999999;
}
a.muted:hover,
a.muted:focus {
  color: #808080;
}
.text-warning {
  color: #c09853;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #a47e3c;
}
.text-error {
  color: #8f1519;
}
a.text-error:hover,
a.text-error:focus {
  color: #630e11;
}
.text-info {
  color: #3a87ad;
}
a.text-info:hover,
a.text-info:focus {
  color: #2d6987;
}
.text-success {
  color: #468847;
}
a.text-success:hover,
a.text-success:focus {
  color: #356635;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 11px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 22px;
  color: inherit;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}
h1,
h2,
h3 {
  line-height: 44px;
}
h1 {
  font-size: 44px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 13.6px;
}
h1 small {
  font-size: 28px;
}
h2 small {
  font-size: 20px;
}
h3 small {
  font-size: 16px;
}
h4 small {
  font-size: 16px;
}
.page-header {
  padding-bottom: 10px;
  margin: 22px 0 33px;
  border-bottom: 1px solid #eeeeee;
}
ul,
ol {
  padding: 0;
  margin: 0 0 11px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 22px;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
}
ul.inline > li,
ol.inline > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-bottom: 22px;
}
dt,
dd {
  line-height: 22px;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 11px;
}
.dl-horizontal {
  *zoom: 1;
}
.dl-horizontal:before,
.dl-horizontal:after {
  display: table;
  content: "";
  line-height: 0;
}
.dl-horizontal:after {
  clear: both;
}
.dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dl-horizontal dd {
  margin-left: 180px;
}
hr {
  margin: 22px 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #ffffff;
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999999;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 22px;
  border-left: 5px solid #eeeeee;
}
blockquote p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
}
blockquote small {
  display: block;
  line-height: 22px;
  color: #999999;
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\00A0 \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 22px;
  font-style: normal;
  line-height: 22px;
}
code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 14px;
  color: #333333;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  white-space: nowrap;
}
pre {
  display: block;
  padding: 10.5px;
  margin: 0 0 11px;
  font-size: 15px;
  line-height: 22px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
pre.prettyprint {
  margin-bottom: 22px;
}
pre code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
form {
  margin: 0 0 22px;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 22px;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
legend small {
  font-size: 16.5px;
  color: #999999;
}
label,
input,
button,
select,
textarea {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}
input,
button,
select,
textarea {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  height: 22px;
  padding: 4px 6px;
  margin-bottom: 11px;
  font-size: 16px;
  line-height: 22px;
  color: #555555;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  vertical-align: middle;
}
input,
textarea,
.uneditable-input {
  width: 206px;
}
textarea {
  height: auto;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  *margin-top: 0;
  /* IE7 */
  margin-top: 1px \9;
  /* IE8-9 */
  line-height: normal;
}
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  height: 32px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */
  line-height: 32px;
}
select {
  width: 220px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.uneditable-input,
.uneditable-textarea {
  color: #999999;
  background-color: #fcfcfc;
  border-color: #cccccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}
.radio,
.checkbox {
  min-height: 22px;
  padding-left: 20px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}
.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}
input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 926px;
}
input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 846px;
}
input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 766px;
}
input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 686px;
}
input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 606px;
}
input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 526px;
}
input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 446px;
}
input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 366px;
}
input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 286px;
}
input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 206px;
}
input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 126px;
}
input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 46px;
}
.controls-row {
  *zoom: 1;
}
.controls-row:before,
.controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.controls-row:after {
  clear: both;
}
.controls-row [class*="span"],
.row-fluid .controls-row [class*="span"] {
  float: left;
}
.controls-row .checkbox[class*="span"],
.controls-row .radio[class*="span"] {
  padding-top: 5px;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
.control-group.warning .control-label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}
.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
}
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #8f1519;
}
.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #8f1519;
}
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  border-color: #8f1519;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #630e11;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #8f1519;
  background-color: #8f1519;
  border-color: #8f1519;
}
.control-group.success .control-label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}
.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
.control-group.info .control-label,
.control-group.info .help-block,
.control-group.info .help-inline {
  color: #3a87ad;
}
.control-group.info .checkbox,
.control-group.info .radio,
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  color: #3a87ad;
}
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  border-color: #3a87ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  border-color: #2d6987;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
.control-group.info .input-prepend .add-on,
.control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
  padding: 21px 20px 22px;
  margin-top: 22px;
  margin-bottom: 22px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}
.help-block,
.help-inline {
  color: #595959;
}
.help-block {
  display: block;
  margin-bottom: 11px;
}
.help-inline {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  display: inline-block;
  margin-bottom: 11px;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu,
.input-append .popover,
.input-prepend .popover {
  font-size: 16px;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  vertical-align: top;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 22px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #eeeeee;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-search .input-append .search-query {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 11px;
}
legend + .control-group {
  margin-top: 22px;
  -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
  margin-bottom: 22px;
  *zoom: 1;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-label {
  float: left;
  width: 160px;
  padding-top: 5px;
  text-align: right;
}
.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  margin-left: 180px;
  *margin-left: 0;
}
.form-horizontal .controls:first-child {
  *padding-left: 180px;
}
.form-horizontal .help-block {
  margin-bottom: 0;
}
.form-horizontal input + .help-block,
.form-horizontal select + .help-block,
.form-horizontal textarea + .help-block,
.form-horizontal .uneditable-input + .help-block,
.form-horizontal .input-prepend + .help-block,
.form-horizontal .input-append + .help-block {
  margin-top: 11px;
}
.form-horizontal .form-actions {
  padding-left: 180px;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  width: 100%;
  margin-bottom: 22px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 22px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid #dddddd;
}
.table .table {
  background-color: #ffffff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
.table td.span1,
.table th.span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}
.table td.span2,
.table th.span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}
.table td.span3,
.table th.span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}
.table td.span4,
.table th.span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}
.table td.span5,
.table th.span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}
.table td.span6,
.table th.span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}
.table td.span7,
.table th.span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}
.table td.span8,
.table th.span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}
.table td.span9,
.table th.span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}
.table td.span10,
.table th.span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}
.table td.span11,
.table th.span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}
.table td.span12,
.table th.span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}
.table tbody tr.success > td {
  background-color: #dff0d8;
}
.table tbody tr.error > td {
  background-color: #8f1519;
}
.table tbody tr.warning > td {
  background-color: #fcf8e3;
}
.table tbody tr.info > td {
  background-color: #d9edf7;
}
.table-hover tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
.table-hover tbody tr.error:hover > td {
  background-color: #791215;
}
.table-hover tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
.table-hover tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 10px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 22px;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: #ffffff;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #999999;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #cccccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.btn:active,
.btn.active {
  background-color: #cccccc \9;
}
.btn:first-child {
  *margin-left: 0;
}
.btn:hover,
.btn:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-large {
  padding: 11px 19px;
  font-size: 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.btn-small {
  padding: 2px 10px;
  font-size: 13.6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}
.btn-mini {
  padding: 0 6px;
  font-size: 12px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #dead02 \9;
}
.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
  *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #bd362f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}
.btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #51a351;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #ffffff;
  background-color: #51a351;
  *background-color: #499249;
}
.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}
.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}
.btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  background-image: -moz-linear-gradient(top, #444444, #222222);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
  background-image: -webkit-linear-gradient(top, #444444, #222222);
  background-image: -o-linear-gradient(top, #444444, #222222);
  background-image: linear-gradient(to bottom, #444444, #222222);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #222222;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
  *background-color: #151515;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}
button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #0088cc;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: #004466;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: #333333;
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: .3em;
}
.btn-group:first-child {
  *margin-left: 0;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 11px;
  margin-bottom: 11px;
}
.btn-toolbar > .btn + .btn,
.btn-toolbar > .btn-group + .btn,
.btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-size: 16px;
}
.btn-group > .btn-mini {
  font-size: 12px;
}
.btn-group > .btn-small {
  font-size: 13.6px;
}
.btn-group > .btn-large {
  font-size: 20px;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #fdc916;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222222;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 8px;
}
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.btn-group-vertical > .btn:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.btn-group-vertical > .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical > .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 22px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.alert,
.alert h4 {
  color: #c09853;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 22px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-success h4 {
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #8f1519;
  border-color: #8f1519;
  color: #8f1519;
}
.alert-danger h4,
.alert-error h4 {
  color: #8f1519;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-info h4 {
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.nav {
  margin-left: 0;
  margin-bottom: 22px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav > li > a > img {
  max-width: none;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #0088cc;
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 10px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.nav-tabs,
.nav-pills {
  *zoom: 1;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #dddddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  color: #ffffff;
  background-color: #0088cc;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
  border-color: #ddd;
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: #0088cc;
  border-bottom-color: #0088cc;
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle:focus .caret {
  border-top-color: #004466;
  border-bottom-color: #004466;
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.nav > .dropdown.active > a:hover,
.nav > .dropdown.active > a:focus {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret,
.nav li.dropdown.open a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover,
.tabs-stacked .open > a:focus {
  border-color: #999999;
}
.tabbable {
  *zoom: 1;
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tab-content {
  overflow: auto;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}
.nav > .disabled > a {
  color: #999999;
}
.nav > .disabled > a:hover,
.nav > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.navbar {
  overflow: visible;
  margin-bottom: 22px;
  *position: relative;
  *z-index: 2;
}
.navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  *zoom: 1;
}
.navbar-inner:before,
.navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-inner:after {
  clear: both;
}
.navbar .container {
  width: auto;
}
.nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.navbar .brand {
  float: left;
  display: block;
  padding: 9px 20px 9px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777777;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .brand:hover,
.navbar .brand:focus {
  text-decoration: none;
}
.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
  color: #777777;
}
.navbar-link {
  color: #777777;
}
.navbar-link:hover,
.navbar-link:focus {
  color: #333333;
}
.navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.navbar .btn,
.navbar .btn-group {
  margin-top: 5px;
}
.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn,
.navbar .input-prepend .btn-group,
.navbar .input-append .btn-group {
  margin-top: 0;
}
.navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}
.navbar-form:before,
.navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-form:after {
  clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: 5px;
}
.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}
.navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.navbar-static-top .navbar-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.navbar-fixed-top {
  top: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.navbar-fixed-bottom {
  bottom: 0;
}
.navbar-fixed-bottom .navbar-inner {
  -webkit-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a {
  float: none;
  padding: 9px 15px 9px;
  color: #777777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  background-color: transparent;
  color: #333333;
  text-decoration: none;
}
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: #555555;
  text-decoration: none;
  background-color: #e5e5e5;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:focus,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
  background-color: #cccccc \9;
}
.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #ffffff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.navbar .nav li.dropdown > a:hover .caret,
.navbar .nav li.dropdown > a:focus .caret {
  border-top-color: #333333;
  border-bottom-color: #333333;
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #555555;
}
.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777777;
  border-bottom-color: #777777;
}
.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  border-color: #252525;
}
.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #999999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .brand:hover,
.navbar-inverse .nav > li > a:hover,
.navbar-inverse .brand:focus,
.navbar-inverse .nav > li > a:focus {
  color: #ffffff;
}
.navbar-inverse .brand {
  color: #999999;
}
.navbar-inverse .navbar-text {
  color: #999999;
}
.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #ffffff;
}
.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #ffffff;
  background-color: #111111;
}
.navbar-inverse .navbar-link {
  color: #999999;
}
.navbar-inverse .navbar-link:hover,
.navbar-inverse .navbar-link:focus {
  color: #ffffff;
}
.navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #ffffff;
}
.navbar-inverse .nav li.dropdown > a:hover .caret,
.navbar-inverse .nav li.dropdown > a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999999;
  border-bottom-color: #999999;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.navbar-inverse .navbar-search .search-query {
  color: #ffffff;
  background-color: #515151;
  border-color: #111111;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #cccccc;
}
.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #cccccc;
}
.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #cccccc;
}
.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #333333;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #ffffff;
  border: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.navbar-inverse .btn-navbar {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
  background-image: -webkit-linear-gradient(top, #151515, #040404);
  background-image: -o-linear-gradient(top, #151515, #040404);
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-inverse .btn-navbar:hover,
.navbar-inverse .btn-navbar:focus,
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active,
.navbar-inverse .btn-navbar.disabled,
.navbar-inverse .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #040404;
  *background-color: #000000;
}
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}
.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 22px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.breadcrumb > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  text-shadow: 0 1px 0 #ffffff;
}
.breadcrumb > li > .divider {
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb > .active {
  color: #999999;
}
.pagination {
  margin: 22px 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination ul > li {
  display: inline;
}
.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 22px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
}
.pagination ul > li > a:hover,
.pagination ul > li > a:focus,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}
.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #999999;
  cursor: default;
}
.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover,
.pagination ul > .disabled > a:focus {
  color: #999999;
  background-color: transparent;
  cursor: default;
}
.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pagination-large ul > li > a,
.pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 20px;
}
.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.pagination-small ul > li > a,
.pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 13.6px;
}
.pagination-mini ul > li > a,
.pagination-mini ul > li > span {
  padding: 0 6px;
  font-size: 12px;
}
.pager {
  margin: 22px 0;
  list-style: none;
  text-align: center;
  *zoom: 1;
}
.pager:before,
.pager:after {
  display: table;
  content: "";
  line-height: 0;
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #999999;
  background-color: #fff;
  cursor: default;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin-left: -280px;
  background-color: #ffffff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  /* IE6-7 */
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.modal.fade {
  -webkit-transition: opacity .3s linear, top .3s ease-out;
  -moz-transition: opacity .3s linear, top .3s ease-out;
  -o-transition: opacity .3s linear, top .3s ease-out;
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 10%;
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-header h3 {
  margin: 0;
  line-height: 30px;
}
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  *zoom: 1;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.popover-title:empty {
  display: none;
}
.popover-content {
  padding: 9px 14px;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow {
  border-width: 11px;
}
.popover .arrow:after {
  border-width: 10px;
  content: "";
}
.popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #ffffff;
}
.popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right .arrow:after {
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #ffffff;
}
.popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #ffffff;
}
.popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left .arrow:after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #ffffff;
  bottom: -10px;
}
.thumbnails {
  margin-left: -20px;
  list-style: none;
  *zoom: 1;
}
.thumbnails:before,
.thumbnails:after {
  display: table;
  content: "";
  line-height: 0;
}
.thumbnails:after {
  clear: both;
}
.row-fluid .thumbnails {
  margin-left: 0;
}
.thumbnails > li {
  float: left;
  margin-bottom: 22px;
  margin-left: 20px;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 22px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.thumbnail:hover,
a.thumbnail:focus {
  border-color: #0088cc;
  -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: #555555;
}
.media,
.media-body {
  overflow: hidden;
  *overflow: visible;
  zoom: 1;
}
.media,
.media .media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}
.media-object {
  display: block;
}
.media-heading {
  margin: 0 0 5px;
}
.media > .pull-left {
  margin-right: 10px;
}
.media > .pull-right {
  margin-left: 10px;
}
.media-list {
  margin-left: 0;
  list-style: none;
}
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 13.536px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #999999;
}
.label {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.label:empty,
.badge:empty {
  display: none;
}
a.label:hover,
a.label:focus,
a.badge:hover,
a.badge:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.label-important,
.badge-important {
  background-color: #8f1519;
}
.label-important[href],
.badge-important[href] {
  background-color: #630e11;
}
.label-warning,
.badge-warning {
  background-color: #f89406;
}
.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
.label-success,
.badge-success {
  background-color: #468847;
}
.label-success[href],
.badge-success[href] {
  background-color: #356635;
}
.label-info,
.badge-info {
  background-color: #3a87ad;
}
.label-info[href],
.badge-info[href] {
  background-color: #2d6987;
}
.label-inverse,
.badge-inverse {
  background-color: #333333;
}
.label-inverse[href],
.badge-inverse[href] {
  background-color: #1a1a1a;
}
.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-mini .label,
.btn-mini .badge {
  top: 0;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  overflow: hidden;
  height: 22px;
  margin-bottom: 22px;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.progress .bar {
  width: 0%;
  height: 100%;
  color: #ffffff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
}
.progress-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-danger .bar,
.progress .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}
.progress-danger.progress-striped .bar,
.progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-success .bar,
.progress .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}
.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar,
.progress .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}
.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-warning .bar,
.progress .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}
.progress-warning.progress-striped .bar,
.progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.accordion {
  margin-bottom: 22px;
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
.carousel {
  position: relative;
  margin-bottom: 22px;
  line-height: 1;
}
.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  line-height: 1;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  background: #222222;
  border: 3px solid #ffffff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.carousel-control.right {
  left: auto;
  right: 15px;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-indicators {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  margin: 0;
  list-style: none;
}
.carousel-indicators li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  text-indent: -999px;
  background-color: #ccc;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}
.carousel-caption h4,
.carousel-caption p {
  color: #ffffff;
  line-height: 22px;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 200;
  line-height: 33px;
  color: inherit;
  background-color: #eeeeee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  color: inherit;
  letter-spacing: -1px;
}
.hero-unit li {
  line-height: 33px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.affix {
  position: fixed;
}
/*!
 * Bootstrap Responsive v2.3.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
@-ms-viewport {
  width: device-width;
}
.hidden {
  display: none;
  visibility: hidden;
}
.visible-phone {
  display: none !important;
}
.visible-tablet {
  display: none !important;
}
.hidden-desktop {
  display: none !important;
}
.visible-desktop {
  display: inherit !important;
}
@media (min-width: 768px) and (max-width: 979px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important ;
  }
  .visible-tablet {
    display: inherit !important;
  }
  .hidden-tablet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-phone {
    display: inherit !important;
  }
  .hidden-phone {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: inherit !important;
  }
  .hidden-print {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -20px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 724px;
  }
  .span12 {
    width: 724px;
  }
  .span11 {
    width: 662px;
  }
  .span10 {
    width: 600px;
  }
  .span9 {
    width: 538px;
  }
  .span8 {
    width: 476px;
  }
  .span7 {
    width: 414px;
  }
  .span6 {
    width: 352px;
  }
  .span5 {
    width: 290px;
  }
  .span4 {
    width: 228px;
  }
  .span3 {
    width: 166px;
  }
  .span2 {
    width: 104px;
  }
  .span1 {
    width: 42px;
  }
  .offset12 {
    margin-left: 764px;
  }
  .offset11 {
    margin-left: 702px;
  }
  .offset10 {
    margin-left: 640px;
  }
  .offset9 {
    margin-left: 578px;
  }
  .offset8 {
    margin-left: 516px;
  }
  .offset7 {
    margin-left: 454px;
  }
  .offset6 {
    margin-left: 392px;
  }
  .offset5 {
    margin-left: 330px;
  }
  .offset4 {
    margin-left: 268px;
  }
  .offset3 {
    margin-left: 206px;
  }
  .offset2 {
    margin-left: 144px;
  }
  .offset1 {
    margin-left: 82px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 32px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.76243094%;
    *margin-left: 2.70923945%;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.76243094%;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851%;
  }
  .row-fluid .span11 {
    width: 91.43646409%;
    *width: 91.3832726%;
  }
  .row-fluid .span10 {
    width: 82.87292818%;
    *width: 82.81973669%;
  }
  .row-fluid .span9 {
    width: 74.30939227%;
    *width: 74.25620078%;
  }
  .row-fluid .span8 {
    width: 65.74585635%;
    *width: 65.69266486%;
  }
  .row-fluid .span7 {
    width: 57.18232044%;
    *width: 57.12912895%;
  }
  .row-fluid .span6 {
    width: 48.61878453%;
    *width: 48.56559304%;
  }
  .row-fluid .span5 {
    width: 40.05524862%;
    *width: 40.00205713%;
  }
  .row-fluid .span4 {
    width: 31.49171271%;
    *width: 31.43852122%;
  }
  .row-fluid .span3 {
    width: 22.9281768%;
    *width: 22.87498531%;
  }
  .row-fluid .span2 {
    width: 14.36464088%;
    *width: 14.31144939%;
  }
  .row-fluid .span1 {
    width: 5.80110497%;
    *width: 5.74791348%;
  }
  .row-fluid .offset12 {
    margin-left: 105.52486188%;
    *margin-left: 105.4184789%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.76243094%;
    *margin-left: 102.65604796%;
  }
  .row-fluid .offset11 {
    margin-left: 96.96132597%;
    *margin-left: 96.85494299%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 94.19889503%;
    *margin-left: 94.09251205%;
  }
  .row-fluid .offset10 {
    margin-left: 88.39779006%;
    *margin-left: 88.29140708%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.63535912%;
    *margin-left: 85.52897614%;
  }
  .row-fluid .offset9 {
    margin-left: 79.83425414%;
    *margin-left: 79.72787116%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 77.0718232%;
    *margin-left: 76.96544023%;
  }
  .row-fluid .offset8 {
    margin-left: 71.27071823%;
    *margin-left: 71.16433525%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.50828729%;
    *margin-left: 68.40190431%;
  }
  .row-fluid .offset7 {
    margin-left: 62.70718232%;
    *margin-left: 62.60079934%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.94475138%;
    *margin-left: 59.8383684%;
  }
  .row-fluid .offset6 {
    margin-left: 54.14364641%;
    *margin-left: 54.03726343%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.38121547%;
    *margin-left: 51.27483249%;
  }
  .row-fluid .offset5 {
    margin-left: 45.5801105%;
    *margin-left: 45.47372752%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.81767956%;
    *margin-left: 42.71129658%;
  }
  .row-fluid .offset4 {
    margin-left: 37.01657459%;
    *margin-left: 36.91019161%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.25414365%;
    *margin-left: 34.14776067%;
  }
  .row-fluid .offset3 {
    margin-left: 28.45303867%;
    *margin-left: 28.3466557%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.69060773%;
    *margin-left: 25.58422476%;
  }
  .row-fluid .offset2 {
    margin-left: 19.88950276%;
    *margin-left: 19.78311978%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.12707182%;
    *margin-left: 17.02068884%;
  }
  .row-fluid .offset1 {
    margin-left: 11.32596685%;
    *margin-left: 11.21958387%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.56353591%;
    *margin-left: 8.45715293%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 710px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 648px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 586px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 524px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 462px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 400px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 338px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 276px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 214px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 152px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 90px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 28px;
  }
}
@media (max-width: 767px) {
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }
  .container-fluid {
    padding: 0;
  }
  .dl-horizontal dt {
    float: none;
    clear: none;
    width: auto;
    text-align: left;
  }
  .dl-horizontal dd {
    margin-left: 0;
  }
  .container {
    width: auto;
  }
  .row-fluid {
    width: 100%;
  }
  .row,
  .thumbnails {
    margin-left: 0;
  }
  .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  [class*="span"],
  .uneditable-input[class*="span"],
  .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .span12,
  .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row-fluid [class*="offset"]:first-child {
    margin-left: 0;
  }
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 32px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    width: auto;
    margin: 0;
  }
  .modal.fade {
    top: -100px;
  }
  .modal.fade.in {
    top: 20px;
  }
}
@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .page-header h1 small {
    display: block;
    line-height: 22px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }
  .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .form-horizontal .control-list {
    padding-top: 0;
  }
  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }
  .media .pull-left,
  .media .pull-right {
    float: none;
    display: block;
    margin-bottom: 10px;
  }
  .media-object {
    margin-right: 0;
    margin-left: 0;
  }
  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .carousel-caption {
    position: static;
  }
}
@media (max-width: 767px) {
  body {
    padding-top: 0;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-top {
    margin-bottom: 22px;
  }
  .navbar-fixed-bottom {
    margin-top: 22px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 11px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777777;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #999999;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 11px 15px;
    margin: 11px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 767px + 1) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}
/* @group COLORES */
/* @end */
/* @group MIXINS */
/* @group HELPERS */
/* @end */
/* @group COMPONENTS */
/* @end */
/* v1.0.5 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
  width: 600px;
  height: 400px;
  position: relative;
  direction: ltr;
}
.royalSlider > * {
  float: left;
}
.rsWebkit3d .rsSlide {
  -webkit-transform: translateZ(0);
}
.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
  -webkit-backface-visibility: hidden;
}
.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
  -webkit-transform: none;
}
.rsOverflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rsVisibleNearbyWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rsVisibleNearbyWrap .rsOverflow {
  position: absolute;
  left: 0;
  top: 0;
}
.rsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rsArrow,
.rsThumbsArrow {
  cursor: pointer;
}
.rsThumb {
  float: left;
  position: relative;
}
.rsArrow,
.rsNav,
.rsThumbsArrow {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.rsHidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -moz-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -o-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  transition: visibility 0s linear 0.3s,opacity 0.3s linear;
}
.rsGCaption {
  width: 100%;
  float: left;
  text-align: center;
}
/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}
.royalSlider .rsSlide.rsFakePreloader {
  opacity: 1 !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  display: none;
}
.rsSlide {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
  height: auto;
}
.rsContent {
  width: 100%;
  height: 100%;
  position: relative;
}
.rsPreloader {
  position: absolute;
  z-index: 0;
}
.rsNav {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}
.rsNavItem {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
}
.rsThumbs {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  z-index: 22;
}
.rsTabs {
  float: left;
  background: none !important;
}
.rsTabs,
.rsThumbs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rsVideoContainer {
  /*left: 0;
	top: 0;
	position: absolute;*/
  /*width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	float: left;*/
  width: auto;
  height: auto;
  line-height: 0;
  position: relative;
}
.rsVideoFrameHolder {
  position: absolute;
  left: 0;
  top: 0;
  background: #141414;
  opacity: 0;
  -webkit-transition: .3s;
}
.rsVideoFrameHolder.rsVideoActive {
  opacity: 1;
}
.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 44px;
}
.rsABlock {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 15;
}
img.rsImg {
  max-width: none;
}
.grab-cursor {
  cursor: url(grab.png) 8 8, move;
}
.grabbing-cursor {
  cursor: url(grabbing.png) 8 8, move;
}
.rsNoDrag {
  cursor: auto;
}
.rsLink {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 20;
  background: url(blank.gif);
}
/******************************
*
*  RoyalSlider White Controls Skin
*
*    1. Arrows 
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*    
*  Sprite: 'rs-minimal-white.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
* 
******************************/
/* Background */
.rsMinW,
.rsMinW .rsOverflow,
.rsMinW .rsSlide,
.rsMinW .rsVideoFrameHolder,
.rsMinW .rsThumbs {
  background: #000;
  color: #FFF;
}
/***************
*
*  1. Arrows
*
****************/
.rsMinW .rsArrow {
  height: 32px;
  width: 32px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 21;
}
.rsMinW .rsArrowLeft {
  right: 37px;
  bottom: 7px;
}
.rsMinW .rsArrowRight {
  right: 7px;
  bottom: 7px;
}
.rsMinW .rsArrowIcn {
  width: 24px;
  height: 24px;
  margin-top: 3px;
  margin-left: 3px;
  position: absolute;
  cursor: pointer;
  background: url('rs-minimal-white.png') transparent;
  border-radius: 2px;
}
.rsMinW.rsHor .rsArrowLeft .rsArrowIcn {
  background-position: -68px -36px;
}
.rsMinW.rsHor .rsArrowRight .rsArrowIcn {
  background-position: -68px -68px;
}
.rsMinW.rsVer .rsArrowLeft .rsArrowIcn {
  background-position: -100px -36px;
}
.rsMinW.rsVer .rsArrowRight .rsArrowIcn {
  background-position: -100px -68px;
}
.rsMinW .rsArrowDisabled .rsArrowIcn {
  opacity: .3 !important;
  filter: alpha(opacity=30);
  *display: none;
}
/***************
*
*  2. Bullets
*
****************/
.rsMinW .rsBullets {
  position: absolute;
  bottom: 15px;
  right: 73px;
  z-index: 35;
  padding-top: 4px;
  height: auto;
  text-align: center;
  line-height: 6px;
  overflow: hidden;
}
.rsMinW .rsBullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 6px 5px 6px 4px;
}
.rsMinW .rsBullet span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #CCC;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.rsMinW .rsBullet.rsNavSelected span {
  background: #FFF;
}
/***************
*
*  3. Thumbnails
*
****************/
.rsMinW .rsThumbsHor {
  width: 100%;
  height: 72px;
}
.rsMinW .rsThumbsVer {
  width: 96px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.rsMinW.rsWithThumbsHor .rsThumbsContainer {
  position: relative;
  height: 100%;
}
.rsMinW.rsWithThumbsVer .rsThumbsContainer {
  position: relative;
  width: 100%;
}
.rsMinW .rsThumb {
  float: left;
  overflow: hidden;
  width: 96px;
  height: 72px;
}
.rsMinW .rsThumb img {
  width: 100%;
  height: 100%;
}
.rsMinW .rsThumb.rsNavSelected {
  background: #333;
}
.rsMinW .rsThumb.rsNavSelected img {
  filter: alpha(opacity=40);
  opacity: 0.7;
}
.rsMinW .rsThumb.rsNavSelected span.thumbIco {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #FFF;
  border: 2px solid rgba(255, 255, 255, 0.9);
  -webkit-backface-visibility: hidden;
}
.rsMinW .rsTmb {
  display: block;
}
/* Thumbnails arrow icons */
.rsMinW .rsThumbsArrow {
  height: 100%;
  width: 20px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 21;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrow {
  width: 100%;
  height: 20px;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrowLeft {
  top: 0;
  left: 0;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrowRight {
  bottom: 0;
  left: 0;
}
.rsMinW.rsWithThumbsHor .rsThumbsArrowLeft {
  left: 0;
  top: 0;
}
.rsMinW.rsWithThumbsHor .rsThumbsArrowRight {
  right: 0;
  top: 0;
}
.rsMinW .rsThumbsArrowIcn {
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  position: absolute;
  cursor: pointer;
  background: url('rs-minimal-white.png');
}
.rsMinW.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -128px -32px;
}
.rsMinW.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -128px -48px;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -144px -32px;
}
.rsMinW.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -144px -48px;
}
.rsMinW .rsThumbsArrowDisabled {
  display: none !important;
}
/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .rsMinW .rsThumb {
    width: 59px;
    height: 44px;
  }
  .rsMinW .rsThumbsHor {
    height: 44px;
  }
  .rsMinW .rsThumbsVer {
    width: 59px;
  }
}
/***************
*
*  4. Tabs
*
****************/
.rsMinW .rsTabs {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  padding-top: 12px;
  position: relative;
}
.rsMinW .rsTab {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: auto;
  width: auto;
  color: #333;
  padding: 5px 13px 6px;
  min-width: 72px;
  border: 1px solid #D9D9DD;
  border-right: 1px solid #f5f5f5;
  text-decoration: none;
  background-color: #000;
  background-image: -webkit-linear-gradient(top, #fefefe, #f4f4f4);
  background-image: -moz-linear-gradient(top, #fefefe, #f4f4f4);
  background-image: linear-gradient(to bottom, #fefefe, #f4f4f4);
  *display: inline;
  *zoom: 1;
}
.rsMinW .rsTab:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.rsMinW .rsTab:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #cfcfcf;
}
.rsMinW .rsTab:active {
  border: 1px solid #D9D9DD;
  background-color: #f4f4f4;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2) inset;
}
.rsMinW .rsTab.rsNavSelected {
  color: #000;
  border: 1px solid #999;
  text-shadow: 1px 1px #838383;
  box-shadow: 0 1px 9px rgba(102, 102, 102, 0.65) inset;
  background: #ACACAC;
  background-image: -webkit-linear-gradient(top, #acacac, #bbbbbb);
  background-image: -moz-llinear-gradient(top, #acacac, #bbbbbb);
  background-image: linear-gradient(to bottom, #acacac, #bbbbbb);
}
/***************
*
*  5. Fullscreen button
*
****************/
.rsMinW .rsFullscreenBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 22;
  display: block;
  position: absolute;
  cursor: pointer;
}
.rsMinW .rsFullscreenIcn {
  display: block;
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url('rs-minimal-white.png') 0 0 transparent;
}
.rsMinW .rsFullscreenIcn:hover {
  opacity: 0.8;
}
.rsMinW.rsFullscreen .rsFullscreenIcn {
  background-position: -32px 0;
}
/***************
*
*  6. Play/close video button
*
****************/
.rsMinW .rsPlayBtn {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  width: 64px;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  cursor: pointer;
}
.rsMinW .rsPlayBtnIcon {
  width: 64px;
  display: block;
  height: 64px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  background: url(rs-minimal-white.png) no-repeat 0 -32px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
}
.rsMinW .rsPlayBtn:hover .rsPlayBtnIcon {
  background-color: rgba(0, 0, 0, 0.9);
}
.rsMinW .rsBtnCenterer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
}
.rsMinW .rsCloseVideoBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 500;
  position: absolute;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}
.rsMinW .rsCloseVideoBtn.rsiOSBtn {
  top: -38px;
  right: -6px;
}
.rsMinW .rsCloseVideoIcn {
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url('rs-minimal-white.png') -64px 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
}
.rsMinW .rsCloseVideoIcn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
/***************
*
*  7. Preloader
*
****************/
.rsMinW .rsPreloader {
  width: 20px;
  height: 20px;
  background-image: url(vendor/preloaders/preloader-white.gif);
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
/***************
*
*  8. Global caption
*
****************/
.rsMinW .rsGCaption {
  position: absolute;
  float: none;
  bottom: 6px;
  left: 6px;
  text-align: left;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: #FFF;
  padding: 2px 8px;
  width: auto;
  font-size: 12px;
  border-radius: 2px;
}
/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
/** THEME
===================================*/
.bx-wrapper .bx-viewport {
  left: -25px;
}
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(vendor/images/bx_loader.gif) center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}
.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}
/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url(images/controls.png) no-repeat 0 -32px;
}
.bx-wrapper .bx-next {
  right: 10px;
  background: url(images/controls.png) no-repeat -43px -32px;
}
.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}
.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}
/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}
/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666\9;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}
/* @group Tooltips */
/* @end */
.tooltip {
  font-size: 12px;
  /* 13 / 16 */
}
.tooltip .tooltip-inner {
  font-size: 1em;
  font-weight: normal;
  font-family: 'Helvetica', 'arial';
  color: #f2f2f2;
  padding: 0.75em;
}
.tooltip.in {
  opacity: 1;
}
/* @end */
/* @group Buttons */
.btn-group {
  font-size: 1em;
}
.btn-group .dropdown-toggle {
  outline: none;
}
.btn-group.open .btn.dropdown-toggle {
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05) !important;
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05) !important;
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05) !important;
}
.coming-soon {
  cursor: default !important;
}
/* @end  */
/* Dropdowns */
.dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
/* Alerts */
.alert {
  margin-bottom: 0;
  padding: 1em 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
  color: #fff;
  text-shadow: none;
  font-weight: 600;
}
.alert > .container {
  position: relative;
}
.alert.alert-error {
  background-color: #8f1519;
  text-shadow: 0 1px 0 #360809;
}
.alert.alert-success {
  background-color: #20b32d;
  text-shadow: 0 1px 0 #115c17;
}
.alert .message {
  padding-right: 2em;
}
.alert .close {
  right: 0;
  top: 2px;
  font-size: 2em;
}
/* Forms */
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  border-color: #555555;
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
select:focus {
  outline: none;
}
form legend {
  display: none;
}
form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
form ul > li dl {
  margin: 0;
}
form ul > li dl dt {
  margin: 0;
}
form ul > li dl dd {
  margin: 0;
}
form ul > li.error .control-label,
form ul > li.error .help-block,
form ul > li.error .help-inline {
  color: #8f1519;
}
form ul > li.error .checkbox,
form ul > li.error .radio,
form ul > li.error input,
form ul > li.error select,
form ul > li.error textarea {
  color: #8f1519;
}
form ul > li.error input,
form ul > li.error select,
form ul > li.error textarea {
  border-color: #8f1519;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
form ul > li.error input:focus,
form ul > li.error select:focus,
form ul > li.error textarea:focus {
  border-color: #630e11;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #e02a30;
}
form ul > li.error .input-prepend .add-on,
form ul > li.error .input-append .add-on {
  color: #8f1519;
  background-color: #8f1519;
  border-color: #8f1519;
}
form ul > li.error input:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
form ul > li.error label {
  color: #8f1519;
}
form .help-inline {
  display: block;
  padding-left: 0;
}
.big-forms label {
  display: none;
}
.big-forms label.radio,
.big-forms label.checkbox,
.big-forms .radios label {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  line-height: 1.6;
}
.big-forms input[type="text"],
.big-forms input[type="email"],
.big-forms input[type="password"],
.big-forms textarea,
.big-forms select {
  width: 100%;
  height: 2.5em;
  margin-bottom: 0.25em;
}
.big-forms li {
  margin-bottom: 0.75em;
}
.big-forms li.error {
  margin-bottom: 1em;
}
.big-forms li.error .help-inline {
  font-size: 0.875em;
  /* 14 / 16 */
  line-height: 1.2;
  margin-bottom: 0.5em;
  padding-top: 0.25em;
}
.contained {
  -webkit-box-shadow: 0px 0px 1px 0px #555555;
  -moz-box-shadow: 0px 0px 1px 0px #555555;
  box-shadow: 0px 0px 1px 0px #555555;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  margin: 4em 0;
}
.contained > header,
.contained > footer,
.contained form fieldset,
.contained form .actions {
  padding: 2em;
}
.contained > header {
  padding-bottom: 1em;
}
.contained > header h1 {
  margin: 0px;
  font-size: 2.000em;
  /* 32 / 16 */
  font-weight: 300;
}
.contained > footer,
.contained form .actions {
  margin-top: 1.5em;
}
.features header {
  margin-bottom: 1em;
}
.features .subsection {
  *zoom: 1;
  overflow: visible;
  position: relative;
  border-bottom: 1px solid #eeeeee;
}
.features .subsection:before,
.features .subsection:after {
  display: table;
  content: "";
  line-height: 0;
}
.features .subsection:after {
  clear: both;
}
.features .subsection .bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 0;
}
.features .subsection .bg-img .container {
  position: relative;
  height: 100%;
  background-size: auto;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.features.block {
  padding-bottom: 0;
}
.features .subsection .badge {
  background: #333333;
  padding: 0.5em 0.75em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1;
  font-size: 0.875em;
}
.features .subsection h2 {
  font-size: 1.875em;
  /* 30 / 16 */
  line-height: 120%;
  font-weight: 300;
  margin-bottom: 1.5em;
}
.features .subsection h2 span {
  display: block;
  font-weight: 400;
}
.features .subsection .feature {
  z-index: 1;
  position: relative;
  padding: 6em 0;
}
.features .subsection .feature.small {
  padding: 8.5em 0;
}
.features .subsection .feature .feature-details {
  width: 80%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.features .subsection .feature .feature-details > li {
  margin-bottom: 1em;
  padding-left: 2em;
  position: relative;
  color: #555555;
}
.features .subsection .feature .feature-details > li:before {
  font-family: 'ecotaxi';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\2a";
  position: absolute;
  top: 0.15em;
  left: 0;
  font-size: 1.2em;
  color: #ffca00;
}
/* Choferes: Features */
#choferes-features .subsection.feature-trips .bg-img .container {
  background-image: url(img/choferes-features-viajes-final.jpg);
  right: 200px;
}
#choferes-features .subsection.feature-payments .bg-img .container {
  background-image: url(img/choferes-features-pagos-final.jpg);
  left: 220px;
  background-color: transparent;
  border: none;
}
#choferes-features .subsection.feature-reputation .bg-img .container {
  background-image: url(img/choferes-features-reputacion-final.jpg);
  background-position: center center;
  right: 230px;
}
#choferes-features .subsection.feature-control .bg-img .container {
  background-image: url(img/choferes-features-control-final.jpg);
  left: 220px;
}
#choferes-features .subsection.feature-payments,
#choferes-features .subsection.feature-control {
  background-color: #f7f7f7;
}
#choferes-features .subsection.feature-control {
  border-bottom: none;
}
/* Choferes: More Features */
#choferes-more-features {
  background-color: #ffca00;
}
#choferes-more-features .feature p {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  color: #000000;
}
#choferes-more-features .feature.feature-smartphone .description {
  background-image: url('img/choferes-more-features-practicidad.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #choferes-more-features .feature.feature-smartphone .description {
    background-image: url('img/choferes-more-features-practicidad@2x.png');
    background-size: 80px 80px;
  }
}
#choferes-more-features .feature.feature-barcode .description {
  background-image: url('img/choferes-more-features-control.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #choferes-more-features .feature.feature-barcode .description {
    background-image: url('img/choferes-more-features-control@2x.png');
    background-size: 80px 80px;
  }
}
#choferes-more-features .feature.feature-points .description {
  background-image: url('img/choferes-more-features-puntos.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #choferes-more-features .feature.feature-points .description {
    background-image: url('img/choferes-more-features-puntos@2x.png');
    background-size: 80px 80px;
  }
}
.btn-large {
  padding: 1em 1.75em;
  font-size: 20px;
}
.btn-xlarge {
  padding: 1em 1.75em;
  font-size: 24px;
}
/* @group - Navbar */
.tpl-inversores .nav-cities,
.tpl-inversores_form .nav-cities,
.tpl-inversores_preguntas .nav-cities {
  display: none;
}
/* @end - Navbar */
/* @group - Intro */
.intro.intro--inversores {
  height: 962px !important;
  height: 77vh !important;
}
.intro.intro--inversores .hero-img {
  background-image: url('img/inversores-banner.jpg');
  background-size: cover;
  background-position: bottom center;
}
.intro.intro--inversores .container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.intro.intro--inversores .intro-text {
  padding-right: 0;
  padding-left: 0;
  background-color: transparent;
  text-align: center;
}
.intro.intro--inversores .intro-text h1 {
  font-size: 3.750em;
  /* 60 / 16 */
  font-weight: 400;
  margin-bottom: 0.5em;
  line-height: 1;
}
.intro.intro--inversores .intro-text h2 {
  font-size: 1.563em;
  /* 25 / 16 */
  margin-bottom: 1.75em;
}
.intro.intro--inversores .intro-text .actions .btn {
  display: inline-block;
}
.intro.intro--inversores .intro-text .actions .btn.btn-xlarge {
  padding: 1em 1.75em;
  font-size: 20px;
}
.hero-continue .arrow-down {
  overflow: hidden;
  z-index: 20;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 25px;
  height: 15px;
  cursor: pointer;
  background-size: contain;
  background-image: url('img/arrow-down.svg');
  background-position: center;
  background-repeat: no-repeat;
  left: 50%;
  bottom: 25px;
  margin-left: -12px;
}
.hero-continue .arrow-down:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
}
.hero-continue .arrow-down.bounce {
  -webkit-animation: arrowBounce 2s infinite;
  animation: arrowBounce 2s infinite;
}
.more-scroll {
  position: absolute;
  padding: 2em;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}
.more-scroll:hover,
.more-scroll:focus {
  color: #fff;
  text-decoration: none;
}
.more-scroll:active,
.more-scroll:visited {
  text-decoration: none;
  color: #fff;
}
.more-scroll-text {
  position: relative;
  top: -2.25em;
}
@-webkit-keyframes arrowBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@keyframes arrowBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/* @end - Intro */
/* @group - Features Inversores */
section .container > header.features-header {
  margin-bottom: 2em !important;
}
section .container > header.features-header h4 {
  line-height: 1.6;
  color: #666666;
}
.features-inversores .subsection.feature-invest-50 .bg-img .container {
  background-image: url(img/inversores-features-50.jpg);
  left: 280px;
  background-position: center center;
}
.features-inversores .subsection.feature-invest-liquid .bg-img .container {
  background-image: url(img/inversores-features-liquidez.jpg);
  right: 220px;
  background-position: center center;
}
.features-inversores .subsection.feature-invest-history .bg-img .container {
  background-image: url(img/inversores-features-historia.jpg);
  left: 225px;
  background-position: center center;
}
.features-inversores .subsection.feature-invest-tech .bg-img .container {
  background-image: url(img/inversores-features-tech.jpg);
  right: 310px;
  background-position: center center;
}
.features-inversores .subsection.feature-invest-profile .bg-img .container {
  background-image: url(img/inversores-perfiles-2.png);
  background-position: right center;
  background-size: contain;
  left: 100px;
}
.features-inversores .subsection.feature-invest-liquid .feature,
.features-inversores .subsection.feature-invest-history .feature {
  padding: 9em 0;
}
.features-inversores .subsection.feature-invest-tech .feature,
.features-inversores .subsection.feature-invest-profile .feature {
  padding: 6em 0;
}
.features-inversores .subsection.feature-invest-liquid,
.features-inversores .subsection.feature-invest-tech {
  background-color: #f7f7f7;
}
.features-inversores .subsection.feature-invest-history h2 {
  margin-bottom: 0.5em;
}
.features-inversores .subsection.feature-invest-history .feature-intro {
  margin-bottom: 2.5em;
}
.features .subsection.feature-invest-profile {
  border-bottom: none;
  background-color: #ffca00;
}
.features .subsection.feature-invest-profile .feature-details > li {
  color: #333333;
}
.features .subsection.feature-invest-profile .feature-details > li::before {
  color: #333;
}
/* @end - Features Inversores */
/* @group - Punchline */
.punchline.punchline--img {
  border-top: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.punchline.punchline--img .cachphrase {
  text-align: center;
}
.punchline.punchline--img .cachphrase p {
  color: #fff;
  text-shadow: 0px 1px 1px #000;
  font-size: 2.500em;
  float: none;
  margin-bottom: 0.75em;
}
.punchline.punchline--img .cachphrase .actions {
  float: none;
  display: inline-block;
}
.punchline.punchline--img .cachphrase .actions > li .btn.btn-xlarge {
  padding: 1em 1.75em;
  font-size: 20px;
}
.punchline.punchline--img.block {
  padding: 9em 0;
}
#inversores-punchline {
  background-image: url('img/inversores-punchline-bg.jpg');
  background-position: center left;
}
#inversores-punchline.punchline .cachphrase .actions > li .btn {
  text-align: center;
}
/* @end - Punchline */
/* @group - Testimonio */
.testimonials .bxslider {
  list-style-type: none;
}
#inversores-testimonials {
  padding: 4em 0 3em 0;
}
#inversores-testimonials blockquote {
  font-weight: 600;
  font-style: italic;
  font-size: 1.70em;
  width: 85%;
}
#inversores-testimonials .quote {
  top: 5px;
}
/* @end - Testimonio */
/* @group - Formulario Inversores */
.empresas-form.inversores-form header {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.empresas-form.inversores-form form .actions {
  text-align: center;
}
.empresas-form.inversores-form form .actions .btn-submit {
  float: none;
}
.empresas-form.inversores-form .row > div:first-child fieldset:after {
  border: none;
}
/* @end - Formulario Inversores */
/*

EcoTaxi v0.1

1. GENERAL
2. COMPONENTS
3. NAVIGATION
4. HOME


*/
/* @group 1. GENERAL */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*
html {
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-smooth:always;
}
*/
body,
html {
  overflow-x: hidden;
}
body {
  padding-left: 0;
  padding-right: 0;
}
section.block {
  padding: 4em 0;
}
section.block.small {
  padding: 2em 0;
}
p {
  line-height: 150%;
}
a:hover {
  text-decoration: none;
}
a:focus {
  outline: none;
}
section .container > header {
  text-align: center;
  margin-bottom: 4em;
}
section .container > header h4 {
  font-size: 18px;
  font-weight: 400;
  color: #999999;
}
section .container > header h3 {
  font-size: 1.875em;
  /* 30 / 16 */
  line-height: 1.2em;
  font-weight: 200;
  margin: 0;
  color: #000000;
}
/* @end */
/* @group 3. NAVIGATION */
/* Barra */
.navbar .navbar-inner {
  border: none;
  border-bottom: 1px solid #dcdede;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.navbar .nav > li > a {
  padding: 23px 15px 20px 15px;
}
.navbar .nav a,
.navbar .btn-navbar-menu {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.813em;
  /* 13 / 16 */
  letter-spacing: 0.1em;
  text-shadow: none;
}
.navbar .nav li:not(.call-to-action) a {
  -webkit-transition: color 0.25s ease;
  -moz-transition: color 0.25s ease;
  transition: color 0.25s ease;
}
.navbar .nav li .dropdown-menu li a {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.navbar .brand {
  margin-left: 0;
  margin-right: 1em;
  margin-top: 0.5em;
  background-image: url('img/logo-nuevo-ecotaxi.png');
  width: 151px;
  height: 42px;
  text-indent: -99999px;
  padding: 0 !important;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  .navbar .brand {
    background-image: url('img/logo-nuevo-ecotaxi@2x.png');
    background-size: 151px 42px;
  }
}
.navbar .primary {
  margin-right: 0;
}
.navbar .primary:before {
  content: '';
  border-right: 1px solid #eeeeee;
  position: absolute;
  top: 1.25em;
  left: 0px;
  height: 2em;
}
.navbar .city-select .dropdown-menu a {
  min-width: 280px;
}
.navbar .city-select a.dropdown-toggle {
  padding-left: 1.5em;
}
.navbar .city-select a.dropdown-toggle .flag {
  top: -2px;
}
.navbar .city-select .flag {
  width: 22px;
  height: 22px;
  top: -1px;
  position: relative;
  margin-right: 0.5em;
}
.navbar .city-select a {
  *zoom: 1;
}
.navbar .city-select a:before,
.navbar .city-select a:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar .city-select a:after {
  clear: both;
}
.navbar .city-select a .city-name {
  float: left;
}
.navbar .city-select a .coming-soon {
  color: #bfbfbf;
  float: right;
  margin-left: 0.5em;
  line-height: 1.75em;
  /*
				&:before {
					content: "\2192";
					margin: 0 0.25em;
				}
				*/
}
.navbar .city-select a.disabled:hover {
  cursor: default;
  background: inherit !important;
}
.navbar .city-select a.disabled:hover .city-name {
  color: #777 !important;
}
.navbar .city-select a.disabled:hover .coming-soon {
  color: #bfbfbf;
}
.navbar .secondary .call-to-action {
  margin-left: 1em;
  margin-top: 1em;
}
.navbar .secondary .call-to-action a {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  border: 1px solid #cccccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  min-width: 130px;
  font-weight: 700;
  line-height: 1.75em;
  letter-spacing: 0;
  text-transform: inherit;
}
.navbar .secondary .call-to-action a:hover,
.navbar .secondary .call-to-action a:focus,
.navbar .secondary .call-to-action a:active,
.navbar .secondary .call-to-action a.active,
.navbar .secondary .call-to-action a.disabled,
.navbar .secondary .call-to-action a[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.navbar .secondary .call-to-action a:active,
.navbar .secondary .call-to-action a.active {
  background-color: #cccccc \9;
}
.navbar .secondary .call-to-action a:first-child {
  *margin-left: 0;
}
.navbar .secondary .call-to-action a:hover,
.navbar .secondary .call-to-action a:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.navbar .secondary .call-to-action a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.navbar .secondary .call-to-action a.active,
.navbar .secondary .call-to-action a:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.navbar .secondary .call-to-action a.disabled,
.navbar .secondary .call-to-action a[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.navbar .secondary .call-to-action a .label,
.navbar .secondary .call-to-action a .badge {
  position: relative;
  top: -1px;
}
.navbar .secondary .call-to-action a:hover,
.navbar .secondary .call-to-action a:focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  background-position: 0 0 ;
}
.navbar .secondary .call-to-action a:hover,
.navbar .secondary .call-to-action a:focus,
.navbar .secondary .call-to-action a:active,
.navbar .secondary .call-to-action a.active,
.navbar .secondary .call-to-action a.disabled,
.navbar .secondary .call-to-action a[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.navbar .secondary .call-to-action a:active,
.navbar .secondary .call-to-action a.active {
  background-color: #dead02 \9;
}
.navbar .secondary .call-to-action a .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.navbar .secondary .call-to-action a:hover,
.navbar .secondary .call-to-action a:focus {
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  background-color: #ffd92a;
  background-image: -moz-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#ffd92a));
  background-image: -webkit-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -o-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: linear-gradient(to bottom, #ffd92a, #ffd92a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#ffffd92a', GradientType=0);
  border-color: #ffd92a #ffd92a #ddb500;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ffd92a;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-color: #d39f29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
}
.navbar .secondary .call-to-action a:hover:hover,
.navbar .secondary .call-to-action a:focus:hover,
.navbar .secondary .call-to-action a:hover:focus,
.navbar .secondary .call-to-action a:focus:focus,
.navbar .secondary .call-to-action a:hover:active,
.navbar .secondary .call-to-action a:focus:active,
.navbar .secondary .call-to-action a:hover.active,
.navbar .secondary .call-to-action a:focus.active,
.navbar .secondary .call-to-action a:hover.disabled,
.navbar .secondary .call-to-action a:focus.disabled,
.navbar .secondary .call-to-action a:hover[disabled],
.navbar .secondary .call-to-action a:focus[disabled] {
  color: #1b1103;
  background-color: #ffd92a;
  *background-color: #ffd411;
}
.navbar .secondary .call-to-action a:hover:active,
.navbar .secondary .call-to-action a:focus:active,
.navbar .secondary .call-to-action a:hover.active,
.navbar .secondary .call-to-action a:focus.active {
  background-color: #f6ca00 \9;
}
.navbar .secondary .call-to-action a.active,
.navbar .secondary .call-to-action a:active {
  color: #1b1103;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.navbar .secondary .call-to-action a.disabled,
.navbar .secondary .call-to-action a[disabled] {
  color: #1b1103;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
}
.navbar .secondary .call-to-action a i {
  font-size: 1.2em;
  margin-right: 0.25em;
  position: relative;
  top: 0.05em;
}
.navbar .dropdown .dropdown-toggle i {
  margin-left: 0.25em;
}
.navbar .dropdown .dropdown-menu {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  top: 3.75em;
  left: 1em;
}
.navbar .dropdown .dropdown-menu a {
  font-weight: 700;
  color: #777;
  font-size: 0.813em;
  /* 13 / 16 */
  letter-spacing: 0;
  line-height: 1;
  padding-top: 0.5em;
  padding-bottom: 0.25em;
}
.navbar .dropdown .dropdown-menu a:hover {
  color: #fff;
}
.navbar .btn,
.navbar .btn-group {
  margin-top: -2px;
}
/* @group - Responsive Nav */
.btn.btn-navbar {
  margin: 14px 18px 0 0;
  -webkit-box-shadow: 0 0 0 transparent !important;
  -moz-box-shadow: 0 0 0 transparent !important;
  box-shadow: 0 0 0 transparent !important;
  background: transparent;
  border-color: #cecece;
  padding: 8px 10px;
}
.btn.btn-navbar .icon-bar {
  text-shadow: none;
  -webkit-box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  background-color: #999999;
}
.btn-navbar-menu {
  display: none;
}
/* @end */
/* @end */
/* @group 4. HERO IMAGE */
body {
  padding-top: 65px;
}
.hero {
  position: relative;
}
.hero .hero-img {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
#counters,
.hero .intro {
  position: relative;
  z-index: 2;
}
.fullWidth {
  max-width: 1920px;
  margin: 0 auto;
}
#full-width-slider {
  position: relative;
  /*position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; 
  height: auto;*/
  width: auto;
}
#full-width-slider .rsGCaption {
  display: none;
}
.intro {
  height: 488px !important;
}
.heroSlider .rsOverflow,
.royalSlider.heroSlider {
  height: 488px !important;
}
@media (min-width: 768px) and (max-width: 979px) {
  .heroSlider .rsOverflow,
  .royalSlider.heroSlider {
    height: 518px !important;
  }
  .intro {
    height: 518px !important;
  }
}
@media (max-width: 767px) {
  .royalSlider.heroSlider,
  .royalSlider.heroSlider .rsOverflow {
    height: 180px !important;
  }
  .intro {
    height: auto !important;
  }
}
/* HERO - Home */
/*
#home-head {
	.hero-img {
		background: url(img/home-banner-02.jpg) no-repeat center center;
		background-size: cover;
	}
	.intro {
		background: none;
	}
}
*/
/* HERO - Choferes */
/*
#choferes-head {
	.hero-img {
		background: url(img/choferes-banner-02.jpg) no-repeat center center;
		background-size: cover;
	}
	.intro {
		background: none;
	}
}
*/
/* @end */
/* @group 4. HOME */
/* @group - Intro */
.intro {
  width: 100%;
  color: #fff;
}
.intro .container > .row {
  padding: 9% 0;
}
.intro .intro-text {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 2em;
}
.intro .intro-text h1,
.intro .intro-text h2,
.intro .intro-text .details {
  text-shadow: 0px 1px 1px #000;
}
.intro .intro-text h1 {
  font-size: 1.938em;
  /* 31 / 16 */
  line-height: 120%;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.intro .intro-text h1 blockquote {
  margin-bottom: 0;
}
.intro .intro-text h1 sup {
  position: relative;
  top: 0px;
  left: -4px;
  font-size: 0.95em;
  font-weight: 400;
}
.intro .intro-text h2 {
  font-size: 1.250em;
  /* 20 / 16 */
  line-height: 140%;
  font-weight: 400;
  margin-bottom: 1.25em;
}
.intro .intro-text h2 strong {
  font-weight: 700;
}
.no-rgba .intro .intro-text {
  background-color: black;
}
.intro.with-video .container > .row {
  padding: 5% 0 9% 0;
}
.intro .actions {
  list-style: none;
  margin: 0;
  padding: 0;
  *zoom: 1;
}
.intro .actions:before,
.intro .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.intro .actions:after {
  clear: both;
}
.intro .actions > li {
  float: left;
  width: 48%;
  vertical-align: top;
}
.intro .actions > li:first-child {
  margin-right: 3%;
}
.intro .actions a.btn {
  padding: 11px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-size: 1.063em;
  /* 17 / 16 */
  line-height: 1;
  padding: 1em 1.75em;
  font-size: 20px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  display: block;
  text-align: center;
  padding: 1em;
  position: relative;
  font-weight: 700;
  font-size: 1.125em;
  /* 18 / 16 */
}
.intro .actions a.btn i {
  position: relative;
  font-size: 1.2em;
  top: 1px;
  left: -5px;
}
.intro .actions a.btn:hover,
.intro .actions a.btn:focus,
.intro .actions a.btn:active,
.intro .actions a.btn.active,
.intro .actions a.btn.disabled,
.intro .actions a.btn[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.intro .actions a.btn:active,
.intro .actions a.btn.active {
  background-color: #dead02 \9;
}
.intro .actions a.btn .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.intro .actions a.btn:hover,
.intro .actions a.btn:focus {
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  background-color: #ffd92a;
  background-image: -moz-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#ffd92a));
  background-image: -webkit-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -o-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: linear-gradient(to bottom, #ffd92a, #ffd92a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#ffffd92a', GradientType=0);
  border-color: #ffd92a #ffd92a #ddb500;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ffd92a;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-color: #d39f29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
}
.intro .actions a.btn:hover:hover,
.intro .actions a.btn:focus:hover,
.intro .actions a.btn:hover:focus,
.intro .actions a.btn:focus:focus,
.intro .actions a.btn:hover:active,
.intro .actions a.btn:focus:active,
.intro .actions a.btn:hover.active,
.intro .actions a.btn:focus.active,
.intro .actions a.btn:hover.disabled,
.intro .actions a.btn:focus.disabled,
.intro .actions a.btn:hover[disabled],
.intro .actions a.btn:focus[disabled] {
  color: #1b1103;
  background-color: #ffd92a;
  *background-color: #ffd411;
}
.intro .actions a.btn:hover:active,
.intro .actions a.btn:focus:active,
.intro .actions a.btn:hover.active,
.intro .actions a.btn:focus.active {
  background-color: #f6ca00 \9;
}
.intro .actions a.btn.active,
.intro .actions a.btn:active {
  color: #1b1103;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.intro .actions a.btn.disabled,
.intro .actions a.btn[disabled] {
  color: #1b1103;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
}
.intro .actions a.btn.with-icon {
  padding: 0.6em 1em .9em 1em;
}
.intro .actions .btn-group {
  width: 100%;
  font-size: 16px;
  display: block;
}
.intro .actions .btn .icon {
  position: relative;
  top: 3px;
  margin-right: 0.2em;
  font-size: 1.5em;
  line-height: 1;
}
.intro .actions .details {
  font-size: 0.813em;
  /* 13 / 16 */
  text-align: center;
  display: block;
  margin-top: 0.75em;
}
.intro .actions .details strong {
  text-transform: uppercase;
  font-weight: 700;
}
.intro .actions .app-downloads .btn .icon {
  left: 1px;
}
.intro .actions .app-downloads .dropdown-toggle .eco-icon-caret-down {
  float: right;
  position: relative;
  left: 0em;
  font-size: 1em;
  top: inherit;
}
/* Download actions */
.actions .app-downloads .dropdown-menu {
  left: inherit;
  text-shadow: none;
  right: 0;
}
.actions .app-downloads .dropdown-menu li:first-child {
  border-bottom: 1px solid #eeeeee;
}
.actions .app-downloads .dropdown-menu li a {
  vertical-align: middle;
  padding: 1em 1em 1em 1em;
  font-size: 0.938em;
}
.actions .app-downloads .dropdown-menu li a i {
  font-size: 2em;
  vertical-align: middle;
  margin-right: 0.25em;
}
.actions .app-downloads .dropdown-menu li a i.eco-icon-apple {
  position: relative;
  top: -2px;
}
.actions .app-downloads .dropdown-menu::before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  left: 9px;
  position: absolute;
  top: -7px;
}
.actions .app-downloads .dropdown-menu::after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  left: 10px;
  position: absolute;
  top: -6px;
}
.actions .app-downloads .dropdown-menu::before {
  left: auto !important;
  right: 13px;
}
.actions .app-downloads .dropdown-menu::after {
  left: auto !important;
  right: 14px;
}
.intro-video {
  margin-top: 1em;
}
.intro-video a {
  display: block;
  text-decoration: none;
  font-weight: 700;
  text-shadow: 0px 1px 1px #000000;
}
.intro-video a:link,
.intro-video a:visited {
  color: #fff;
  text-decoration: none;
}
.intro-video img {
  margin-right: 0.75em;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
}
.intro-video span {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  width: 40%;
}
/* @end */
/* @group - Contadores */
#counters {
  background-color: #f7f5f2;
}
#counters .counter dl {
  margin: 0;
}
#counters .counter dl .qty {
  margin: 0;
  font-size: 2.9em;
  /* 48 / 16 */
  font-weight: 400;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  margin-right: 20px;
}
#counters .counter dl dd {
  margin: 0;
  color: #555555;
  font-size: 0.938em;
  /* 15 / 16 */
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
}
#counters .counter .numbers {
  color: white;
  white-space: nowrap;
  position: relative;
  direction: ltr;
  vertical-align: middle;
}
#counters .counter .number {
  width: 1em;
  height: 1.35em;
  margin-left: -3px;
  margin-right: -3px;
  position: relative;
  text-align: center;
  line-height: 1.5em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  z-index: 2;
  color: #333333;
  text-shadow: 0 1px 0 #fff;
  border: 1px solid #b6b6b6;
  /* stroke */
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* prevents bg color from leaking outside the border */
  background-color: #fff;
  /* layer fill content */
  background-image: -moz-linear-gradient(bottom, rgba(218, 218, 218, 0.33) 17.5%, rgba(255, 255, 255, 0) 82.5%);
  /* gradient overlay */
  background-image: -o-linear-gradient(bottom, rgba(218, 218, 218, 0.33) 17.5%, rgba(255, 255, 255, 0) 82.5%);
  /* gradient overlay */
  background-image: -webkit-linear-gradient(bottom, rgba(218, 218, 218, 0.33) 17.5%, rgba(255, 255, 255, 0) 82.5%);
  /* gradient overlay */
  background-image: linear-gradient(bottom, rgba(218, 218, 218, 0.33) 17.5%, rgba(255, 255, 255, 0) 82.5%);
  /* gradient overlay */
  -webkit-box-shadow: inset 0 0px 0px 1px #ffffff, /* inner shadow */ 0px 1px 0px 0px #ffffff, 0px 2px 0px 0px #c0c0c0, 0px 3px 0px 0px #ffffff, 0px 4px 0px 0px #c0c0c0;
  box-shadow: inset 0 0px 0px 1px #ffffff, /* inner shadow */ 0px 1px 0px 0px #ffffff, 0px 2px 0px 0px #c0c0c0, 0px 3px 0px 0px #ffffff, 0px 4px 0px 0px #c0c0c0;
}
#counters .counter .number:before {
  content: '';
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  height: 1px;
  border-top: 1px solid rgba(182, 182, 182, 0.5);
  z-index: -1;
}
#counters .counter .number:after {
  content: '';
  width: 100%;
  display: block;
  position: absolute;
  top: 51%;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: -1;
}
#counters #taxi-counter dl dd {
  width: 35%;
}
#counters #passenger-counter dl dd {
  width: 30%;
}
#counters.home-counters {
  text-align: center;
}
#counters.home-counters dd {
  text-align: left;
}
/* @end */
/* @group - Features */
.big.features,
.mini.features {
  list-style-type: none;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
}
.big.features h4,
.mini.features h4,
.big.features h5,
.mini.features h5 {
  font-weight: 600;
  color: #000000;
  line-height: 1;
  margin-bottom: 0.75em;
}
#home-features {
  padding-bottom: 1em;
  position: relative;
}
#home-features .gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: -1;
  background: #fff;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VmZWZlZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk5JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top, #efefef 0%, #ffffff 99%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efefef), color-stop(99%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #efefef 0%, #ffffff 99%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #efefef 0%, #ffffff 99%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #efefef 0%, #ffffff 99%);
  /* IE10+ */
  background: linear-gradient(to bottom, #efefef 0%, #ffffff 99%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#efefef', endColorstr='#ffffff', GradientType=0);
  /* IE6-8 */
}
.ie9 #home-features {
  filter: none;
}
.big.features img {
  margin-bottom: 2em;
}
.big.features h4 {
  font-size: 1.250em;
  /* 20 / 16 */
}
.big.features p {
  color: #555555;
  width: 90%;
  margin: 0 auto;
}
.more-features .description {
  padding-top: 85px;
}
.more-features .description h4 {
  font-size: 1.125em;
  /* 18 / 16 */
}
.more-features .description p {
  font-size: 0.875em;
  /* 14 / 16 */
  min-height: 3em;
  color: #555555;
}
.more-features .feature .description {
  background-position: top center;
  background-size: 80px 80px;
  background-repeat: no-repeat;
}
#home-more-features header {
  margin-bottom: 2.75em;
}
#home-more-features .description {
  padding-top: 100px;
}
#home-more-features header,
#empresas-testimonials header {
  text-align: center;
  position: relative;
}
#home-more-features h3,
#empresas-testimonials h3 {
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #9da0b0;
  margin-bottom: 0em;
  font-size: 0.875em;
  /* 14 / 16 */
  font-weight: 700;
}
#home-more-features h3:before,
#empresas-testimonials h3:before,
#home-more-features h3:after,
#empresas-testimonials h3:after {
  background-color: #e4e4e9;
  content: "";
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 15%;
}
#home-more-features h3:before,
#empresas-testimonials h3:before {
  right: 1em;
  margin-left: -50%;
}
#home-more-features h3:after,
#empresas-testimonials h3:after {
  left: 1em;
  margin-right: -50%;
}
#home-more-features {
  padding-top: 3em;
}
#home-more-features .feature .description {
  padding-top: 100px;
}
#home-more-features .feature h5 {
  display: none;
}
#home-more-features .feature.feature-realtime .description {
  background-image: url('img/home-more-features-ubicacion.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #home-more-features .feature.feature-realtime .description {
    background-image: url('img/home-more-features-ubicacion@2x.png');
    background-size: 80px 80px;
  }
}
#home-more-features .feature.feature-trust .description {
  background-image: url('img/home-more-features-chofer.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #home-more-features .feature.feature-trust .description {
    background-image: url('img/home-more-features-chofer@2x.png');
    background-size: 80px 80px;
  }
}
#home-more-features .feature.feature-points .description {
  background-image: url('img/home-more-features-kilometros.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #home-more-features .feature.feature-points .description {
    background-image: url('img/home-more-features-kilometros@2x.png');
    background-size: 80px 80px;
  }
}
#home-more-features .feature.feature-eco .description {
  background-image: url('img/home-more-features-ecologia.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #home-more-features .feature.feature-eco .description {
    background-image: url('img/home-more-features-ecologia@2x.png');
    background-size: 80px 80px;
  }
}
/* @end */
/* @group - Testimoniales */
.testimonials.block {
  padding-top: 3.5em;
  padding-bottom: 2em;
}
.testimonials {
  background-color: #f7f5f2;
  overflow: hidden;
}
.testimonials > .container {
  position: relative;
}
.testimonials .quote {
  font-size: 16.000em;
  position: absolute;
  top: 20px;
  color: #d6d7dd;
}
.testimonials .quote.left-quote {
  left: 2%;
}
.testimonials .quote.right-quote {
  right: 2%;
}
.testimonials blockquote {
  font-size: 1.500em;
  /* 24 / 16 */
  line-height: 140%;
  text-align: center;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  border: none;
  margin-bottom: 1em;
}
.testimonials .author {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 14px;
  color: #3a3b47;
}
.testimonials .author span {
  font-weight: 400;
  color: #676a80;
}
.testimonials .people {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.testimonials .people li {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  margin-right: 3%;
}
.testimonials .people li:last-child {
  margin-right: 0;
}
.testimonials .people li a img {
  filter: url("data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'><filter%20id='grayscale'><feColorMatrix%20type='matrix'%20values='0.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200%200%200%201%200'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  filter: gray;
  /* IE6+ */
  -webkit-filter: grayscale(1);
  /* Google Chrome & Safari 6+ */
  opacity: 0.50;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.testimonials .people li.active a img,
.testimonials .people li a:hover img {
  opacity: 1;
  filter: none;
  -webkit-filter: none;
}
.testimonials .bx-wrapper {
  margin-bottom: 0;
}
.testimonials .bx-wrapper .bx-pager.bx-default-pager {
  bottom: -35px;
}
.testimonials .bx-wrapper .bx-pager.bx-default-pager .bx-pager-link {
  background-color: #d6d7dd;
}
.testimonials .bx-wrapper .bx-pager.bx-default-pager .bx-pager-link.active {
  background-color: #515363;
}
/* @end */
/* @group - Selector Empresas / Choferes */
#selector {
  background-color: #ffca00;
}
#selector .a_empresas.option .description {
  background-image: url('img/home-sos-empresa.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #selector .a_empresas.option .description {
    background-image: url('img/home-sos-empresa@2x.png');
    background-size: 90px 90px;
  }
}
#selector .a_choferes.option .description {
  background-image: url('img/home-sos-chofer.png');
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #selector .a_choferes.option .description {
    background-image: url('img/home-sos-chofer@2x.png');
    background-size: 90px 90px;
  }
}
#selector .option .description {
  padding-left: 120px;
  width: 80%;
  background-size: 90px 90px;
  background-position: left top;
  background-repeat: no-repeat;
}
#selector h3 {
  font-size: 22px;
  /* 20 / 16 */
  font-weight: 600;
  color: #222222;
  margin-top: 0;
  line-height: 1;
  margin-bottom: 0.25em;
}
#selector p {
  margin-bottom: 1em;
}
#selector .btn {
  background: transparent;
  color: #222222;
  font-weight: 400;
  text-shadow: none !important;
  border: none;
  text-shadow: none;
  padding: 0.5em 1em;
  color: #000000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #f9e06e;
  background-image: -moz-linear-gradient(top, #f9e06e, #f9e06e);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9e06e), to(#f9e06e));
  background-image: -webkit-linear-gradient(top, #f9e06e, #f9e06e);
  background-image: -o-linear-gradient(top, #f9e06e, #f9e06e);
  background-image: linear-gradient(to bottom, #f9e06e, #f9e06e);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9e06e', endColorstr='#fff9e06e', GradientType=0);
  border-color: #f9e06e #f9e06e #f6d025;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f9e06e;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
#selector .btn:hover,
#selector .btn:focus,
#selector .btn:active,
#selector .btn.active,
#selector .btn.disabled,
#selector .btn[disabled] {
  color: #000000;
  background-color: #f9e06e;
  *background-color: #f8db56;
}
#selector .btn:active,
#selector .btn.active {
  background-color: #f7d63d \9;
}
#selector .btn i {
  margin-left: 0.75em;
}
#selector .btn:hover {
  color: #000000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fee9a4;
  background-image: -moz-linear-gradient(top, #fee9a4, #fee9a4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fee9a4), to(#fee9a4));
  background-image: -webkit-linear-gradient(top, #fee9a4, #fee9a4);
  background-image: -o-linear-gradient(top, #fee9a4, #fee9a4);
  background-image: linear-gradient(to bottom, #fee9a4, #fee9a4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffee9a4', endColorstr='#fffee9a4', GradientType=0);
  border-color: #fee9a4 #fee9a4 #fdd758;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fee9a4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
#selector .btn:hover:hover,
#selector .btn:hover:focus,
#selector .btn:hover:active,
#selector .btn:hover.active,
#selector .btn:hover.disabled,
#selector .btn:hover[disabled] {
  color: #000000;
  background-color: #fee9a4;
  *background-color: #fee38b;
}
#selector .btn:hover:active,
#selector .btn:hover.active {
  background-color: #fddd72 \9;
}
/* @end */
/* @group - Cierre */
.punchline.block {
  padding: 3em 0 2em 0;
}
.punchline {
  border-top: 1px solid #eeeeee;
}
.punchline .cachphrase p {
  float: left;
  font-size: 1.563em;
  /* 25 / 16 */
  color: #222222;
  line-height: 1.2em;
  margin-bottom: 1em;
  margin-top: 0.2em;
}
.punchline .cachphrase p i {
  margin-left: 0.5em;
  font-size: 1.2em;
  position: relative;
  top: 0.1em;
  color: #999999;
}
.punchline .cachphrase .actions {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
  *zoom: 1;
}
.punchline .cachphrase .actions:before,
.punchline .cachphrase .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.punchline .cachphrase .actions:after {
  clear: both;
}
.punchline .cachphrase .actions > li {
  float: left;
  margin-left: 0.75em;
}
.punchline .cachphrase .actions > li .details {
  font-size: 0.813em;
  /* 13/ 16 */
  display: block;
  margin-top: 0.5em;
  color: #555555;
  text-align: center;
}
.punchline .cachphrase .actions > li .details strong {
  text-transform: uppercase;
}
.punchline .cachphrase .actions > li .btn {
  padding: 11px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-size: 1.063em;
  /* 17 / 16 */
  line-height: 1;
  padding: 1em 1.75em;
  font-size: 20px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  color: #1b1103;
  font-weight: 700;
  text-shadow: 0px 1px 1px #fff0aa;
  text-align: left;
  position: relative;
}
.punchline .cachphrase .actions > li .btn i {
  position: relative;
  font-size: 1.2em;
  top: 1px;
  left: -5px;
}
.punchline .cachphrase .actions > li .btn:hover,
.punchline .cachphrase .actions > li .btn:focus,
.punchline .cachphrase .actions > li .btn:active,
.punchline .cachphrase .actions > li .btn.active,
.punchline .cachphrase .actions > li .btn.disabled,
.punchline .cachphrase .actions > li .btn[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.punchline .cachphrase .actions > li .btn:active,
.punchline .cachphrase .actions > li .btn.active {
  background-color: #dead02 \9;
}
.punchline .cachphrase .actions > li .btn .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.punchline .cachphrase .actions > li .btn:hover,
.punchline .cachphrase .actions > li .btn:focus {
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  background-color: #ffd92a;
  background-image: -moz-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#ffd92a));
  background-image: -webkit-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -o-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: linear-gradient(to bottom, #ffd92a, #ffd92a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#ffffd92a', GradientType=0);
  border-color: #ffd92a #ffd92a #ddb500;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ffd92a;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-color: #d39f29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
}
.punchline .cachphrase .actions > li .btn:hover:hover,
.punchline .cachphrase .actions > li .btn:focus:hover,
.punchline .cachphrase .actions > li .btn:hover:focus,
.punchline .cachphrase .actions > li .btn:focus:focus,
.punchline .cachphrase .actions > li .btn:hover:active,
.punchline .cachphrase .actions > li .btn:focus:active,
.punchline .cachphrase .actions > li .btn:hover.active,
.punchline .cachphrase .actions > li .btn:focus.active,
.punchline .cachphrase .actions > li .btn:hover.disabled,
.punchline .cachphrase .actions > li .btn:focus.disabled,
.punchline .cachphrase .actions > li .btn:hover[disabled],
.punchline .cachphrase .actions > li .btn:focus[disabled] {
  color: #1b1103;
  background-color: #ffd92a;
  *background-color: #ffd411;
}
.punchline .cachphrase .actions > li .btn:hover:active,
.punchline .cachphrase .actions > li .btn:focus:active,
.punchline .cachphrase .actions > li .btn:hover.active,
.punchline .cachphrase .actions > li .btn:focus.active {
  background-color: #f6ca00 \9;
}
.punchline .cachphrase .actions > li .btn.active,
.punchline .cachphrase .actions > li .btn:active {
  color: #1b1103;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.punchline .cachphrase .actions > li .btn.disabled,
.punchline .cachphrase .actions > li .btn[disabled] {
  color: #1b1103;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
}
.punchline .cachphrase .actions > li .btn.dropdown-toggle i.eco-icon-caret-down {
  font-size: 1em;
  position: relative;
  left: 0.25em;
}
.punchline .facebook-like {
  clear: both;
}
#home-punchline,
#choferes-punchline {
  padding-bottom: 1.5em;
}
/* @end */
/* @end */
/* @group 5. FOOTER */
footer.footer {
  border-top: 1px solid #eeeeee;
  padding: 3em 0;
  padding-bottom: 6em;
  margin-bottom: 3.5em;
  background: url('img/iso.png') no-repeat center bottom;
  text-align: center;
}
footer.footer .footer-links {
  font-size: 0.938em;
  /* 15 / 16 */
  text-align: center;
}
footer.footer .footer-links > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
  border-right: 1px solid #eeeeee;
  margin-right: 1.5em;
  margin-bottom: 1em;
  top: -9px;
  position: relative;
}
footer.footer .footer-links > ul:last-child {
  border: none;
  margin-right: 0;
}
footer.footer .footer-links > ul:last-child li:last-child {
  margin-right: 0;
}
footer.footer .footer-links > ul > li {
  display: inline;
  margin-right: 1.35em;
}
footer.footer .footer-links .social {
  position: relative;
}
footer.footer .footer-links .social li {
  display: inline;
  margin-right: 0.25em;
  position: relative;
}
footer.footer .footer-links .social li a {
  font-size: 2.5em;
  /* 28 / 16 */
  text-decoration: none;
  position: relative;
  top: 9px;
}
footer.footer .footer-links .social li a span {
  display: none;
}
footer.footer .footer-links .social .seguinos {
  position: relative;
  padding-right: 0.5em;
  color: #999999;
}
footer.footer .footer-links {
  margin-bottom: 2.5em;
}
footer.footer .app-links {
  margin-bottom: 2em;
}
footer.footer .copy {
  font-size: 0.813em;
  /* 13 / 16 */
  color: #999999;
}
.social .facebook a i {
  color: #3b5998;
}
.social .facebook a:hover i {
  color: #263961;
}
.social .twitter a i {
  color: #4099ff;
}
.social .twitter a:hover i {
  color: #0071f3;
}
.social .google-plus a i {
  color: #d34836;
}
.social .google-plus a:hover i {
  color: #9b3022;
}
.app-links > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-links .app-badge {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  margin-right: .5em;
}
.app-links .app-badge:last-child {
  margin-right: 0;
}
.app-links .app-badge a {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #cccccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background: transparent;
  font-size: 0.938em;
  /* 15 / 16 */
  padding: 0.5em 1em;
}
.app-links .app-badge a:hover,
.app-links .app-badge a:focus,
.app-links .app-badge a:active,
.app-links .app-badge a.active,
.app-links .app-badge a.disabled,
.app-links .app-badge a[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.app-links .app-badge a:active,
.app-links .app-badge a.active {
  background-color: #cccccc \9;
}
.app-links .app-badge a:first-child {
  *margin-left: 0;
}
.app-links .app-badge a:hover,
.app-links .app-badge a:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.app-links .app-badge a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.app-links .app-badge a.active,
.app-links .app-badge a:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.app-links .app-badge a.disabled,
.app-links .app-badge a[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.app-links .app-badge a .label,
.app-links .app-badge a .badge {
  position: relative;
  top: -1px;
}
.app-links .app-badge a:hover,
.app-links .app-badge a:focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.25);
  background-position: 0 0 ;
}
.app-links .app-badge a i {
  font-size: 1.25em;
  position: relative;
  top: 0.15em;
  right: 0.15em;
}
/* @end */
/* @group 6. CHOFERES */
/* @group - Intro choferes */
#choferes-head .intro-text {
  text-align: center;
}
#choferes-head .intro-text .hero-testimony {
  margin-bottom: 1.5em;
}
#choferes-head .intro-text .hero-testimony blockquote {
  padding: 0;
  border: none;
  margin-bottom: 0em;
}
#choferes-head .intro-text .hero-testimony blockquote h1 {
  font-size: 1.625em;
  /* 26 / 16 */
  line-height: 1.25em;
  font-weight: 700;
  margin-bottom: 0;
}
#choferes-head .intro-text .author {
  display: block;
  margin-top: 0.75em;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 0.875em;
  /* 14 / 26 */
  text-transform: uppercase;
  line-height: 1.5;
}
#choferes-head .intro-text .author .author-data {
  font-weight: 400;
}
#choferes-head .intro-text h2 {
  font-size: 1em;
  /* 16 / 16 */
  margin-bottom: 0em;
}
#choferes-head .intro-text h2 strong {
  display: block;
}
#choferes-head .actions .try-ecotaxi {
  display: block;
  text-align: center;
  font-size: 1.063em;
  /* 17 */
  font-size: 1.125em;
  /* 18 / 16 */
}
/* @end */
/* @group - Contadores choferes */
#counters.choferes-counters {
  padding: 2.5em 0;
  text-align: center;
}
#counters.choferes-counters #passenger-counter {
  position: relative;
  top: 0.25em;
  text-align: center;
  margin-right: 4em;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
}
#counters.choferes-counters #passenger-counter .qty {
  display: block;
  margin-right: 0;
}
#counters.choferes-counters #passenger-counter dd {
  display: block;
  width: 100%;
}
#counters.choferes-counters #passenger-counter .numbers {
  margin-bottom: 0.30em;
}
#counters.choferes-counters h2 {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  text-align: left;
  font-size: 1.750em;
  /* 28 / 16 */
  line-height: 1.2;
  margin-bottom: 0;
  position: relative;
  font-weight: 400;
}
#counters.choferes-counters h2 .location {
  display: block;
  font-weight: 700;
}
/* @end */
/* @group - Cierre choferes */
/* @end */
/* @end */
/* @group 7. FORMS */
.form-template {
  background-color: #f7f5f2;
}
.contained {
  background: #ffffff;
}
.contained header h1 {
  font-size: 1.875em;
  /* 30 / 16 */
  line-height: 1.2;
  margin-bottom: 0.5em;
}
.contained header p {
  font-size: 0.938em;
  /* 15 / 16 */
  color: #808080;
}
.contained form fieldset {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.contained form fieldset h3 {
  margin-top: 0;
  font-size: 0.938em;
  /* 15 / 16 */
  line-height: 2.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.contained form fieldset h3 i {
  font-size: 1.75em;
  margin-right: 0.25em;
  position: relative;
  top: 0.1em;
}
.contained form fieldset ul li.radios {
  float: left;
  width: 100%;
  margin-top: 0.5em;
}
.contained form fieldset ul li.radios dd label {
  text-transform: uppercase;
}
.choferes-form form .name,
.empresas-form form .name,
.choferes-form form .last-name,
.empresas-form form .last-name {
  float: left;
  width: 48%;
}
.choferes-form form .mobile,
.empresas-form form .mobile,
.choferes-form form .owner-radios,
.empresas-form form .owner-radios {
  clear: both;
}
.choferes-form form .car-type,
.empresas-form form .car-type,
.choferes-form form .car-year,
.empresas-form form .car-year {
  float: left;
}
.choferes-form form .car-type,
.empresas-form form .car-type {
  width: 68%;
}
.choferes-form form .car-year,
.empresas-form form .car-year {
  width: 28%;
}
.choferes-form form .name,
.empresas-form form .name,
.choferes-form form .car-type,
.empresas-form form .car-type {
  margin-right: 4%;
}
.choferes-form form .radios .radio.inline,
.empresas-form form .radios .radio.inline {
  padding-right: 30%;
}
.choferes-form form .address-number,
.empresas-form form .address-number,
.choferes-form form .address-dept,
.empresas-form form .address-dept,
.choferes-form form .address-cp,
.empresas-form form .address-cp {
  float: left;
  width: 30%;
}
.choferes-form form .address-cp,
.empresas-form form .address-cp {
  width: 32%;
}
.choferes-form form .address-number,
.empresas-form form .address-number,
.choferes-form form .address-dept,
.empresas-form form .address-dept {
  margin-right: 4%;
}
.choferes-form form .actions,
.empresas-form form .actions {
  vertical-align: middle;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background-color: #f6f6f6;
  *zoom: 1;
}
.choferes-form form .actions:before,
.empresas-form form .actions:before,
.choferes-form form .actions:after,
.empresas-form form .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.choferes-form form .actions:after,
.empresas-form form .actions:after {
  clear: both;
}
.choferes-form form .actions .terms-checkbox,
.empresas-form form .actions .terms-checkbox {
  margin-top: 0.75em;
}
.choferes-form form .actions .terms-checkbox li,
.empresas-form form .actions .terms-checkbox li,
.choferes-form form .actions .terms-checkbox li.error,
.empresas-form form .actions .terms-checkbox li.error {
  margin-bottom: 0;
}
.choferes-form form .actions .terms-checkbox .checkbox,
.empresas-form form .actions .terms-checkbox .checkbox {
  font-size: 0.875em;
  /* 14 /  16 */
}
.choferes-form form .actions .btn-submit,
.empresas-form form .actions .btn-submit {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  color: #1b1103;
  font-weight: 700;
  text-shadow: 0px 1px 1px #fff0aa;
  padding: 11px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 1.063em;
  /* 17 / 16 */
  line-height: 1;
  padding: 1em 1.75em;
  font-size: 20px;
  float: right;
}
.choferes-form form .actions .btn-submit:hover,
.empresas-form form .actions .btn-submit:hover,
.choferes-form form .actions .btn-submit:focus,
.empresas-form form .actions .btn-submit:focus,
.choferes-form form .actions .btn-submit:active,
.empresas-form form .actions .btn-submit:active,
.choferes-form form .actions .btn-submit.active,
.empresas-form form .actions .btn-submit.active,
.choferes-form form .actions .btn-submit.disabled,
.empresas-form form .actions .btn-submit.disabled,
.choferes-form form .actions .btn-submit[disabled],
.empresas-form form .actions .btn-submit[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.choferes-form form .actions .btn-submit:active,
.empresas-form form .actions .btn-submit:active,
.choferes-form form .actions .btn-submit.active,
.empresas-form form .actions .btn-submit.active {
  background-color: #dead02 \9;
}
.choferes-form form .actions .btn-submit .caret,
.empresas-form form .actions .btn-submit .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.choferes-form form .actions .btn-submit:hover,
.empresas-form form .actions .btn-submit:hover,
.choferes-form form .actions .btn-submit:focus,
.empresas-form form .actions .btn-submit:focus {
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  background-color: #ffd92a;
  background-image: -moz-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#ffd92a));
  background-image: -webkit-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -o-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: linear-gradient(to bottom, #ffd92a, #ffd92a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#ffffd92a', GradientType=0);
  border-color: #ffd92a #ffd92a #ddb500;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ffd92a;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-color: #d39f29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
}
.choferes-form form .actions .btn-submit:hover:hover,
.empresas-form form .actions .btn-submit:hover:hover,
.choferes-form form .actions .btn-submit:focus:hover,
.empresas-form form .actions .btn-submit:focus:hover,
.choferes-form form .actions .btn-submit:hover:focus,
.empresas-form form .actions .btn-submit:hover:focus,
.choferes-form form .actions .btn-submit:focus:focus,
.empresas-form form .actions .btn-submit:focus:focus,
.choferes-form form .actions .btn-submit:hover:active,
.empresas-form form .actions .btn-submit:hover:active,
.choferes-form form .actions .btn-submit:focus:active,
.empresas-form form .actions .btn-submit:focus:active,
.choferes-form form .actions .btn-submit:hover.active,
.empresas-form form .actions .btn-submit:hover.active,
.choferes-form form .actions .btn-submit:focus.active,
.empresas-form form .actions .btn-submit:focus.active,
.choferes-form form .actions .btn-submit:hover.disabled,
.empresas-form form .actions .btn-submit:hover.disabled,
.choferes-form form .actions .btn-submit:focus.disabled,
.empresas-form form .actions .btn-submit:focus.disabled,
.choferes-form form .actions .btn-submit:hover[disabled],
.empresas-form form .actions .btn-submit:hover[disabled],
.choferes-form form .actions .btn-submit:focus[disabled],
.empresas-form form .actions .btn-submit:focus[disabled] {
  color: #1b1103;
  background-color: #ffd92a;
  *background-color: #ffd411;
}
.choferes-form form .actions .btn-submit:hover:active,
.empresas-form form .actions .btn-submit:hover:active,
.choferes-form form .actions .btn-submit:focus:active,
.empresas-form form .actions .btn-submit:focus:active,
.choferes-form form .actions .btn-submit:hover.active,
.empresas-form form .actions .btn-submit:hover.active,
.choferes-form form .actions .btn-submit:focus.active,
.empresas-form form .actions .btn-submit:focus.active {
  background-color: #f6ca00 \9;
}
.choferes-form form .actions .btn-submit.active,
.empresas-form form .actions .btn-submit.active,
.choferes-form form .actions .btn-submit:active,
.empresas-form form .actions .btn-submit:active {
  color: #1b1103;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.choferes-form form .actions .btn-submit.disabled,
.empresas-form form .actions .btn-submit.disabled,
.choferes-form form .actions .btn-submit[disabled],
.empresas-form form .actions .btn-submit[disabled] {
  color: #1b1103;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
}
.choferes-form form .actions .btn-submit i,
.empresas-form form .actions .btn-submit i {
  position: relative;
  font-size: 1.2em;
  top: 1px;
  left: -5px;
}
.choferes-form form .eco-icon-user,
.empresas-form form .eco-icon-user,
.choferes-form form .eco-icon-briefcase,
.empresas-form form .eco-icon-briefcase {
  font-size: 1.5em;
}
.choferes-form .row > div:last-child fieldset {
  position: relative;
}
.choferes-form .row > div:last-child fieldset:after {
  border-right: 1px solid #eeeeee;
  display: block;
  height: 100%;
  content: " ";
  width: 1px;
  position: absolute;
  left: -10px;
  top: 0;
}
.empresas-form .row > div:first-child fieldset {
  position: relative;
}
.empresas-form .row > div:first-child fieldset:after {
  border-right: 1px solid #eeeeee;
  display: block;
  height: 100%;
  content: " ";
  width: 1px;
  position: absolute;
  right: -10px;
  top: 0;
}
/* @end */
/* @group 8. EMPRESA */
/* Intro */
#empresas-head .intro > .container {
  position: relative;
}
#empresas-head .phones {
  position: absolute;
  z-index: 2;
  bottom: -20px;
  right: 0;
  width: 400px;
}
/* Features */
#empresas-features .features {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
#empresas-features .features .feature {
  *zoom: 1;
  margin-bottom: 3.5em;
}
#empresas-features .features .feature:before,
#empresas-features .features .feature:after {
  display: table;
  content: "";
  line-height: 0;
}
#empresas-features .features .feature:after {
  clear: both;
}
#empresas-features .features .feature img {
  width: 30%;
  float: left;
  margin-right: 4%;
}
#empresas-features .features .feature .description {
  width: 60%;
  float: left;
}
#empresas-features .features .feature .description h4 {
  font-size: 1.125em;
  /* 18 / 16 */
  margin-top: 0;
  color: #000000;
  font-weight: 600;
}
#empresas-features .features .feature .description p {
  color: #555555;
}
#empresas-features .features .feature.last,
#empresas-features .features .feature.second-to-last {
  margin-bottom: 0;
}
/* Testimonials */
.testimonials.brands.block {
  padding: 3.5em 0;
}
.testimonials.brands.block header {
  margin-bottom: 2.5em;
}
/* @end */
/* @group 9. PAGES */
.page {
  padding: 3em 0;
}
.page h1 {
  font-size: 2em;
  line-height: 1.25em;
  margin: 0px;
  font-weight: 300;
}
.page h2 {
  font-size: 20px;
}
.page .row > header,
.page section > header {
  margin-bottom: 2em;
}
aside.lat-nav {
  padding-left: 1.5em;
}
aside.lat-nav ul {
  list-style: none;
  margin: 0;
}
aside.lat-nav ul li a {
  display: block;
  padding: 0.25em 0;
}
aside.lat-nav ul li a i {
  margin-right: 0.25em;
}
aside.lat-nav .social h5 {
  font-weight: 400;
  font-size: 0.938em;
  /* 15 / 16 */
  color: #999999;
}
aside.lat-nav .social a {
  font-size: 0.938em;
  /* 15 / 16 */
}
aside.lat-nav .social a i {
  font-size: 1.067em;
  /* 16 / 15 */
}
aside.lat-nav .links {
  border-top: 1px solid #eeeeee;
  margin-bottom: 2em;
}
aside.lat-nav .links li {
  border-bottom: 1px solid #eeeeee;
}
aside.lat-nav .links li a {
  padding: 0.5em 0;
}
aside.lat-nav .links li.active a {
  cursor: default;
  color: #333333;
}
aside.lat-nav .links li.active a:hover,
aside.lat-nav .links li.active a:active {
  color: #555555;
}
.page .terms h2 {
  font-size: 18px;
  line-height: 1.25em;
  margin: 1.5em 0;
}
.page .terms h2 span {
  color: #999999;
}
.page .terms .updated-on {
  padding: 0.75em 1em;
  margin-bottom: 2em;
  background-color: #eeeeee;
  font-weight: 600;
}
.page .terms p,
.page .terms ul,
.page .terms ol {
  font-size: 0.875em;
  /* 14 / 16 */
}
.page .terms ol {
  list-style-type: lower-roman;
}
.page .terms ol.letters {
  list-style-type: lower-alpha;
}
.back-to-top a {
  float: right;
  padding: 1em 0;
}
.faqs .accordion-group {
  margin-bottom: 0.5em;
}
.faqs .accordion-inner {
  background-color: #f3f3f3;
  padding-top: 1em;
  padding-bottom: 1em;
}
.faqs .accordion-inner p:last-child {
  margin-bottom: 0;
}
.faqs .accordion-toggle {
  font-weight: 600;
}
.contact-form .instructions {
  color: #999999;
  margin-bottom: 1.5em;
  font-size: 0.938em;
}
.contact-form textarea#messagetxt {
  height: 8em;
}
.contact-form .btn {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fed322;
  background-image: -moz-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#fdc916));
  background-image: -webkit-linear-gradient(top, #ffd92a, #fdc916);
  background-image: -o-linear-gradient(top, #ffd92a, #fdc916);
  background-image: linear-gradient(to bottom, #ffd92a, #fdc916);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#fffdc916', GradientType=0);
  border-color: #fdc916 #fdc916 #c59902;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #fdc916;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  color: #1b1103;
  font-weight: 700;
  text-shadow: 0px 1px 1px #fff0aa;
  padding: 11px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 1.063em;
  /* 17 / 16 */
  line-height: 1;
  padding: 1em 1.75em;
  font-size: 20px;
}
.contact-form .btn:hover,
.contact-form .btn:focus,
.contact-form .btn:active,
.contact-form .btn.active,
.contact-form .btn.disabled,
.contact-form .btn[disabled] {
  color: #ffffff;
  background-color: #fdc916;
  *background-color: #f7c002;
}
.contact-form .btn:active,
.contact-form .btn.active {
  background-color: #dead02 \9;
}
.contact-form .btn .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.contact-form .btn:hover,
.contact-form .btn:focus {
  color: #1b1103;
  text-shadow: 0px 1px 1px #fff0aa;
  background-color: #ffd92a;
  background-image: -moz-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffd92a), to(#ffd92a));
  background-image: -webkit-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: -o-linear-gradient(top, #ffd92a, #ffd92a);
  background-image: linear-gradient(to bottom, #ffd92a, #ffd92a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffd92a', endColorstr='#ffffd92a', GradientType=0);
  border-color: #ffd92a #ffd92a #ddb500;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ffd92a;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-color: #d39f29;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 3px rgba(0,0,0,.25);
}
.contact-form .btn:hover:hover,
.contact-form .btn:focus:hover,
.contact-form .btn:hover:focus,
.contact-form .btn:focus:focus,
.contact-form .btn:hover:active,
.contact-form .btn:focus:active,
.contact-form .btn:hover.active,
.contact-form .btn:focus.active,
.contact-form .btn:hover.disabled,
.contact-form .btn:focus.disabled,
.contact-form .btn:hover[disabled],
.contact-form .btn:focus[disabled] {
  color: #1b1103;
  background-color: #ffd92a;
  *background-color: #ffd411;
}
.contact-form .btn:hover:active,
.contact-form .btn:focus:active,
.contact-form .btn:hover.active,
.contact-form .btn:focus.active {
  background-color: #f6ca00 \9;
}
.contact-form .btn.active,
.contact-form .btn:active {
  color: #1b1103;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.contact-form .btn.disabled,
.contact-form .btn[disabled] {
  color: #1b1103;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.10);
}
.contact-form .btn i {
  position: relative;
  font-size: 1.2em;
  top: 1px;
  left: -5px;
}
/* @end */
/* MEDIA QUERIES */
/* @group 979px - Inherited */
@media (max-width: 979px) {
  /* @group HOME */
  .navbar-fixed-top {
    margin-bottom: 0;
  }
  .navbar .navbar-inner {
    padding: 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 20;
  }
  .intro .intro-text h2 {
    font-size: 1em;
    /* 16 */
  }
  .intro .actions > li {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  .intro .actions > li.app-downloads {
    margin-bottom: 0.5em;
  }
  .intro .actions .details {
    display: none;
  }
  #choferes-head .intro-text h2 strong {
    display: inline;
  }
  #counters .counter {
    position: relative;
    top: 0.25em;
  }
  #counters .counter dl {
    text-align: center;
  }
  #counters .counter dl .qty {
    float: none;
    margin-right: 0;
  }
  #counters .counter dl dd {
    float: none;
    font-size: 0.938em;
    text-align: center;
  }
  #counters .counter .numbers {
    margin-bottom: 0.30em;
  }
  #counters #passenger-counter dl dd,
  #counters #taxi-counter dl dd {
    width: 100%;
  }
  .big.features p {
    font-size: 0.875em;
    /* 14 / 16 */
    width: 100%;
  }
  #selector .option .description {
    width: 100%;
  }
  .testimonials .quote {
    top: 0.05em;
    font-size: 14.000em;
  }
  .testimonials blockquote {
    font-size: 1.375em;
    /* 22 / 16 */
  }
  .punchline .cachphrase .actions > li a {
    text-align: left;
  }
  /* @end */
}
/* @end */
/* @group 979px - 768px */
@media (min-width: 768px) and (max-width: 979px) {
  #home-more-features {
    /* remove clear from each ul to stop them from breaking into rows */
    /* make the width of each span equal to 47% instead of 100%.
  		You could make it closer to 50% if you have no borders or padding etc. */
  }
  #home-more-features .features::after {
    clear: none;
  }
  #home-more-features .features li[class*="span"] {
    width: 47%;
    float: left;
  }
  #home-more-features [class*="span"] .span4:nth-child(2),
  #home-more-features [class*="span"] .row-fluid:nth-child(even) .span4:nth-child(1),
  #home-more-features [class*="span"] .span3:nth-child(even) {
    float: right;
  }
  #home-more-features .feature {
    margin-bottom: 1em;
  }
  #home-more-features .feature .description {
    background-position: center 10%;
  }
  #home-more-features .feature p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .punchline .cachphrase .actions > li {
    display: block;
    float: none;
    margin-bottom: 0.5em;
  }
  .punchline .cachphrase .actions > li a {
    width: 100%;
  }
  footer.footer .footer-links {
    margin-bottom: 2em;
  }
  footer.footer .footer-links > ul {
    border-right: none;
    margin-right: 0;
    display: block;
  }
  /* @group CHOFERES           */
  #choferes-head .intro .intro-text {
    padding: 2em 1.5em;
  }
  #choferes-head .intro .intro-text .hero-testimony blockquote h1 {
    font-size: 22px;
    /* 18 / 16 */
    line-height: 130%;
  }
  #choferes-head .intro .intro-text .hero-testimony .author-name {
    display: block;
  }
  /* @group - Features choferes */
  #choferes-features .subsection .bg-img .container {
    background-size: auto 90%;
  }
  #choferes-features .subsection .feature {
    padding: 4em 0;
  }
  #choferes-features .subsection .feature h2 {
    font-size: 1.625em;
    /* 26 / 16 */
    margin-bottom: 1em;
  }
  #choferes-features .subsection.feature-reputation .bg-img .container {
    right: 175px;
  }
  #choferes-features .subsection.feature-invest-50 .bg-img .container {
    background-size: auto 80%;
    left: 210px;
  }
  #choferes-features .subsection.feature-invest-liquid .bg-img .container {
    right: 150px;
  }
  #choferes-features .subsection.feature-invest-history .bg-img .container {
    background-size: auto 75%;
    left: 200px;
  }
  #choferes-features .subsection.feature-invest-tech .bg-img .container {
    background-size: auto 100%;
    right: 210px;
  }
  #choferes-features .subsection.feature-invest-profile .bg-img .container {
    background-size: auto 65%;
    left: auto;
    right: 0;
  }
  /* @end */
  /* @end */
  /* @group EMPRESAS            */
  /* Intro */
  #empresas-head .phones {
    bottom: -70px;
    width: 320px;
  }
  /* Features */
  #empresas-features .features .feature .description {
    width: 66%;
  }
  #empresas-features .features .feature p {
    font-size: 0.875em;
    /* 14 / 16 */
  }
  /* @end */
}
/* @end */
/* @group 767px */
@media (max-width: 767px) {
  body {
    padding-top: 0px;
  }
  .container {
    padding: 0 20px;
  }
  section.block {
    padding: 2.5em 0;
  }
  section.block.small {
    padding: 2em 0;
  }
  section .container > header {
    margin-bottom: 2em;
  }
  section .container > header h3 {
    font-size: 1.375em;
    /* 22 / 16 */
  }
  .navbar-fixed-top {
    margin-bottom: 0;
  }
  .navbar .container {
    padding: 0 15px;
  }
  .navbar .navbar-inner {
    padding: 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 20;
  }
  .navbar .brand {
    padding: 1em;
    margin: 12px 20px;
    position: relative;
    top: -1px;
    width: 108px;
    height: 30px;
    background-size: 108px 30px;
  }
  .btn-navbar-menu {
    float: right;
    cursor: pointer;
    display: inline;
    color: #999999;
    padding-top: 1.75em;
    padding-right: 0.75em;
    line-height: 1;
  }
  .btn-navbar-menu:link,
  .btn-navbar-menu:visited {
    color: #999999;
  }
  .btn-navbar-menu:hover {
    color: #555555;
  }
  .nav-collapse.collapse .nav > li > a,
  .nav-collapse.collapse .dropdown-menu > li > a {
    margin-bottom: 0;
    padding: 1em 1.5em;
    line-height: 1.5;
  }
  .nav-collapse.collapse .nav > li > a:hover,
  .nav-collapse.collapse .dropdown-menu > li > a:hover {
    background: inherit;
  }
  .nav-collapse.collapse .nav > li > a.dropdown-toggle,
  .nav-collapse.collapse .dropdown-menu > li > a.dropdown-toggle {
    background: inherit;
  }
  .nav-collapse.collapse .nav > li > a.dropdown-toggle .icon-caret-down,
  .nav-collapse.collapse .dropdown-menu > li > a.dropdown-toggle .icon-caret-down {
    float: right;
    position: relative;
    top: 0.1em;
    font-size: 16px;
  }
  .nav-collapse.collapse .nav {
    font-size: 1em;
    margin-bottom: 0;
  }
  .nav-collapse.collapse .nav > li {
    border-bottom: 1px solid #eeeeee;
  }
  .nav-collapse.collapse .nav .call-to-action {
    margin-left: 0;
    padding: 1em 1.25em;
    border-bottom: none !important;
    margin-top: 0;
    font-size: 1em;
  }
  .nav-collapse.collapse .nav .call-to-action .btn {
    text-align: left;
    display: block;
  }
  .nav-collapse.collapse .nav .dropdown .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .nav-collapse.collapse .nav .dropdown .dropdown-menu a {
    font-size: 14px;
  }
  .nav-collapse.collapse .nav .dropdown .dropdown-menu a:hover {
    color: #555555;
  }
  .nav-collapse.collapse .nav .city-select.dropdown .flag {
    height: 16px;
    width: 16px;
    top: -2px;
  }
  .nav-collapse,
  .brand {
    top: 0;
  }
  .hero .hero-img {
    height: 180px;
    position: relative;
  }
  .intro .container > .row {
    padding: 0;
  }
  .intro .intro-text {
    background: #222222;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1.5em;
    text-align: center;
  }
  .intro .intro-text .actions .app-downloads {
    display: block;
    margin: 0 auto;
  }
  .intro .intro-text .actions .app-downloads .btn {
    text-align: center;
    font-size: 1.063em;
    /* 17 */
  }
  .intro .intro-text .actions .app-downloads .dropdown-menu {
    text-align: left;
    width: 100%;
  }
  .intro.intro--inversores {
    height: auto !important;
  }
  .intro.intro--inversores .container {
    top: 0%;
    transform: translateY(0%);
  }
  #home-head .intro .intro-text h1,
  #choferes-head .intro .intro-text h1,
  #empresas-head .intro .intro-text h1,
  #inversores-head .intro .intro-text h1 {
    font-size: 1.375em;
    /* 22 / 16 */
  }
  #home-head .intro .intro-text h2,
  #choferes-head .intro .intro-text h2,
  #empresas-head .intro .intro-text h2,
  #inversores-head .intro .intro-text h2 {
    font-size: 0.938em;
    /* 15 / 16 */
    margin-left: auto;
    margin-right: auto;
  }
  .intro.intro--inversores .intro-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  #choferes-head .intro .intro-text .hero-testimony {
    margin-bottom: 1.5em;
  }
  #choferes-head .intro .intro-text .hero-testimony blockquote h1 {
    font-size: 1.125em;
    /* 18 / 16 */
    line-height: 140%;
  }
  #choferes-head .intro .intro-text .hero-testimony .author {
    font-size: 0.722em;
    /* 13 / 18 */
  }
  #choferes-head .intro .intro-text .hero-testimony .author .author-name {
    display: block;
  }
  #counters .counter {
    top: 0 !important;
  }
  #counters .counter dl {
    text-align: center;
  }
  #counters .counter dl .qty {
    float: none;
    margin-right: 0;
  }
  #counters .counter dl dd {
    float: none;
    font-size: 0.875em;
    /* 14 */
  }
  #counters .counter .numbers {
    margin-bottom: 0.35em;
    font-size: 0.8em;
  }
  #counters #passenger-counter dl dd,
  #counters #taxi-counter dl dd {
    width: 100%;
  }
  #counters #taxi-counter {
    margin-bottom: 2em;
  }
  #home-features.block {
    padding-bottom: 0;
  }
  #home-features .gradient {
    height: 30px;
  }
  #home-features .big.features .feature {
    margin-bottom: 3em;
  }
  #home-features .big.features img {
    max-width: 295px;
    margin-bottom: 1.5em;
  }
  #home-features .big.features h4 {
    margin-bottom: 0.5em;
  }
  .more-features .feature {
    margin-bottom: 2em;
  }
  .more-features .feature.last {
    margin-bottom: 0;
  }
  .more-features .feature .description {
    text-align: left;
    padding-top: 0;
    padding-left: 85px;
    background-position: 5px top;
    background-size: 60px 60px !important;
  }
  .more-features .feature .description h4 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1em;
  }
  .more-features .feature .description p {
    font-size: 0.875em;
    /* 14 / 16 */
  }
  #home-more-features {
    padding-top: 0em;
  }
  #home-more-features h3 {
    margin-bottom: 2em;
  }
  #home-more-features .feature {
    margin-bottom: 0.5em;
  }
  #home-more-features .feature p {
    padding-top: 2em;
    margin-bottom: 0;
  }
  #home-more-features .feature .description {
    padding-top: 0;
    background-position: 5px center;
    min-height: 5em;
  }
  .testimonials .quote {
    font-size: 13em ;
    top: 5px;
  }
  .testimonials blockquote {
    font-size: 1.125em;
    /* 18 / 16 */
    width: 85%;
  }
  #selector .option .description {
    text-align: center;
    padding-left: 0px;
    padding-top: 105px;
    background-position: center top;
    width: 85% !important;
    margin: 0 auto;
  }
  #selector .option.a_empresas {
    margin-bottom: 2.5em;
  }
  #selector h3 {
    font-size: 1.250em;
    /* 20 / 16 */
  }
  #selector p {
    font-size: 0.875em;
    /* 14 / 16 */
  }
  .punchline .cachphrase > p {
    float: none;
    font-size: 1.250em;
    /* 18 / 16 */
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .punchline .cachphrase > p i {
    display: none;
  }
  .punchline .cachphrase .actions {
    margin: 0 auto;
    margin-top: 1.5em;
    text-align: left;
    float: none;
  }
  .punchline .cachphrase .actions > li {
    margin-left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0.5em;
  }
  .punchline .cachphrase .actions > li > a {
    display: block;
  }
  .punchline .cachphrase .actions > li .btn-group {
    display: block;
  }
  .punchline .cachphrase .actions > li .btn {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .punchline .cachphrase .actions > li .btn:after {
    content: "\f054";
    font-family: 'ecotaxi';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.75;
    color: #999999;
    -webkit-font-smoothing: antialiased;
    float: right;
  }
  .punchline .cachphrase .actions > li .btn:after {
    color: #d6aa00;
    text-shadow: none;
    line-height: 1.2em;
  }
  .punchline .cachphrase .actions > li .btn.dropdown-toggle:after {
    content: "";
  }
  .punchline .cachphrase .actions > li .btn.dropdown-toggle.with-icon {
    padding-top: 0.85em;
    padding-bottom: 0.85em;
  }
  .punchline .cachphrase .actions > li .btn.dropdown-toggle i.eco-icon-caret-down {
    float: right;
  }
  .punchline .cachphrase .actions > li .dropdown-menu {
    text-align: left;
    width: 100%;
  }
  .facebook-like {
    overflow: hidden;
    padding-top: 1.5em;
  }
  footer.footer {
    padding: 1.5em 0;
    padding-bottom: 6em;
    margin-bottom: 3.5em;
  }
  footer.footer nav.footer-links {
    margin-bottom: 1.5em;
  }
  footer.footer nav.footer-links ul {
    display: block;
    text-align: left;
    border-right: 0;
    margin: 0px;
  }
  footer.footer nav.footer-links ul li {
    display: block;
  }
  footer.footer nav.footer-links ul li a {
    padding: 0.5em 0;
    display: block;
  }
  footer.footer nav.footer-links ul.social {
    text-align: center;
    top: 0;
    margin-bottom: 2.5em;
  }
  footer.footer nav.footer-links ul.social li {
    float: none;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    *display: inline;
    margin-right: 0.5em;
  }
  footer.footer nav.footer-links ul.social li a {
    font-size: 2.500em;
    /* 40 / 16 */
    float: none;
    padding: 0;
  }
  footer.footer nav.footer-links ul.social .seguinos {
    margin-right: 0.5em;
    top: 0.75em;
  }
  footer.footer .app-links ul {
    margin: 0 auto;
    border: 1px solid #cecece;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
  }
  footer.footer .app-links ul li {
    display: block;
    margin-right: 0;
    margin-bottom: 0em;
    border-bottom: 1px solid #cecece;
  }
  footer.footer .app-links ul li:last-child {
    border-bottom: none;
  }
  footer.footer .app-links a {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    padding: 0.75em;
    width: 100%;
    display: block;
    text-align: left;
    padding: 1em 1.25em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  footer.footer .app-links a:after {
    content: "\f054";
    font-family: 'ecotaxi';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.75;
    color: #999999;
    -webkit-font-smoothing: antialiased;
    float: right;
  }
  footer.footer .app-links a i {
    position: relative;
    top: 2px;
  }
  footer.footer .app-links a:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  footer.footer .copy span {
    display: block;
  }
  /* @group CHOFERES           */
  #choferes-head .rsCaption {
    display: none;
  }
  /* @group - Features choferes */
  #choferes-features header h4 {
    font-size: 0.938em;
  }
  #choferes-features .subsection .bg-img {
    height: 220px;
    position: relative;
  }
  #choferes-features .subsection .bg-img .container {
    height: 220px;
    background-position: center bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
    left: 0 !important;
  }
  #choferes-features .subsection .feature,
  #choferes-features .subsection .feature.small {
    padding: 2em 0 0 0;
  }
  #choferes-features .subsection .feature .badge,
  #choferes-features .subsection .feature.small .badge {
    font-size: 0.750em;
    /* 12 / 16 */
  }
  #choferes-features .subsection .feature h2,
  #choferes-features .subsection .feature.small h2 {
    font-size: 1.250em;
    /* 20 / 16 */
  }
  #choferes-features .subsection .feature .feature-details,
  #choferes-features .subsection .feature.small .feature-details {
    width: 100%;
  }
  #choferes-features .subsection .feature .feature-details > li,
  #choferes-features .subsection .feature.small .feature-details > li {
    font-size: 0.875em;
    /* 14 / 16 */
    line-height: 150%;
  }
  #choferes-features .subsection.feature-invest-history {
    padding-bottom: 2em;
  }
  #choferes-more-features .feature h4 {
    font-size: 1em;
  }
  #choferes-more-features .feature p {
    width: 100%;
  }
  /* @end */
  /* @group - Contadores choferes */
  #counters.choferes-counters #passenger-counter {
    margin-right: 0;
    margin-bottom: 1.5em;
    display: block;
  }
  #counters.choferes-counters h2 {
    text-align: center;
    font-size: 1.250em;
    /* 20 / 16 */
    top: 0;
    margin-top: 0;
  }
  #counters.choferes-counters h2 .location {
    display: inline;
  }
  /* @end */
  /* @end */
  /* @group FORMS           */
  .alert {
    font-size: 0.875em;
    /* 14 / 16 */
    line-height: 1.25;
  }
  .form-template {
    background-color: #ffffff;
  }
  .contained {
    margin: 0;
    margin-left: -20px;
    margin-right: -20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .contained > header,
  .contained form fieldset,
  .contained form .actions {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contained > header h1 {
    font-size: 1.375em;
    /* 22 / 16 */
  }
  .contained header p {
    font-size: 0.875em;
    /* 14 / 16 */
  }
  .contained form .actions {
    margin-top: 0;
    margin-bottom: 0;
  }
  .contained form .actions .terms-checkbox {
    margin-bottom: 1.25em;
  }
  .contained form .actions .btn-submit {
    display: block;
    float: none;
    width: 100%;
  }
  .contained form {
    margin-bottom: 0;
  }
  .choferes-form form fieldset {
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid #eeeeee;
  }
  .choferes-form form fieldset.last {
    margin-bottom: 0;
  }
  .choferes-form form fieldset h3 {
    font-size: 0.813em;
    /* 13 / 16 */
  }
  .choferes-form form .row > div:last-child fieldset:after {
    border-right: none;
  }
  .choferes-form form .actions .checkbox {
    margin-top: 0;
  }
  /* @end */
  /* @group 8. EMPRESA */
  /* Intro */
  #empresas-head .hero-img {
    z-index: -2;
  }
  #empresas-head .phones {
    width: 180px;
    z-index: 1;
    bottom: inherit;
    top: -150px;
    right: 5%;
  }
  #empresas-head .intro-text {
    position: relative;
    z-index: 2;
  }
  /* Features */
  #empresas-features .features .feature {
    margin-bottom: 1.5em;
  }
  #empresas-features .features .feature img {
    width: 25%;
  }
  #empresas-features .features .feature .description {
    width: 71%;
  }
  #empresas-features .features .feature .description h4 {
    font-size: 1em;
    margin-bottom: 0.5em;
  }
  #empresas-features .features .feature .description p {
    font-size: 0.875em;
  }
  #empresas-features .features .feature.second-to-last {
    margin-bottom: 1.5em;
  }
  #empresas-features .features .feature.last {
    margin-bottom: 0;
  }
  /* Testimonials */
  .testimonials.brands.block .people li {
    margin-bottom: 1em;
  }
  /* @end */
  /* @group INVERSORES */
  .intro.intro--inversores .intro-text {
    background: #222222;
  }
  .intro.intro--inversores .intro-text .actions .btn {
    display: block;
  }
  .punchline.punchline--img .cachphrase .actions {
    display: block;
  }
  .more-scroll {
    display: none;
  }
  .intro.intro--inversores .intro-text .actions .btn.btn-xlarge,
  .punchline.punchline--img .cachphrase .actions > li .btn.btn-xlarge {
    padding: 1em 1.25em;
  }
  #inversores-punchline.punchline .cachphrase .actions > li .btn:after {
    display: none;
  }
  /* @end */
  /* @group PAGES */
  .back-to-top {
    text-align: center;
    margin-top: 2.5em;
  }
  .back-to-top a {
    display: block;
    background-color: #eeeeee;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float: none;
  }
  .faqs .accordion-toggle {
    font-size: 15px;
    line-height: 130%;
  }
  .faqs .accordion-inner {
    background-color: #f3f3f3;
    font-size: 0.875em;
    /* 14 / 16 */
  }
  /* @end */
  /* @end */
}
/*# sourceMappingURL=css/main.css.map */